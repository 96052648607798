import { Card, Link } from "@mui/joy";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CancellationModal from "../CancellationModal/CancellationModal";
import DocumentViewerModal from "../DocumentViewerModal/DocumentViewerModal";

export default function FinalOptionsList({
  order,
  hideOrganiserContact = false,
}) {
  const navigate = useNavigate();
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
  const [documentViewerOpen, setDocumentViewerOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);

  const openDocumentViewer = (documentId) => {
    setDocumentId(documentId);
    setDocumentViewerOpen(true);
  };

  return (
    <Card>
      <DocumentViewerModal
        documentId={documentId}
        open={documentViewerOpen}
        setOpenFn={setDocumentViewerOpen}
      />
      <CancellationModal
        state={order}
        openFn={setIsCancellationModalOpen}
        open={isCancellationModalOpen}
      />

      <Link onClick={() => setIsCancellationModalOpen(true)}>
        Cancellation options
      </Link>
      <Link onClick={() => navigate("/my/payment-methods")}>
        Payment methods
      </Link>
      <Link onClick={() => openDocumentViewer("terms_of_service")}>
        Terms &amp; Conditions
      </Link>
      <Link onClick={() => openDocumentViewer("privacy_policy")}>
        Privacy Policy
      </Link>
      {!hideOrganiserContact && (
        <Link href="#contact">
          Contact the organiser:{" "}
          {order.listingData.merchantAccount.businessName}
        </Link>
      )}
      <Link href={`mailto:hello@metimeaway.com`}>Contact Me Time Away</Link>
    </Card>
  );
}
