import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useGetMediaQuery } from "../../../../../services/rtk/osare";
import Divider from "../../UI/Divider/Divider";
import SectionTitle from "../../UI/SectionTitle/SectionTitle";

// Instructors section component
// Maps overs all an instructors object to extract their information
export default function Instructors({ instructorsData }) {
  return (
    <>
      <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="Instructors" />
        <div className="flex flex-col gap-[32px]">
          {instructorsData.map((instructor) => (
            <InstructorCard key={instructor._id} instructor={instructor} />
          ))}
        </div>
      </section>
      <Divider />
    </>
  );
}

// Instructor card component displays each instructors' information
const InstructorCard = ({ instructor }) => {
  const { data, isLoading, isSuccess } = useGetMediaQuery({
    collection: "staff",
    collectionId: instructor._id,
  });

  const editor = useEditor({
    extensions: [StarterKit],
    content: instructor.bio,
  });

  return (
    <div className="w-full h-fit bg-white flex flex-col xl:flex-row xl:gap-[80px] py-[32px] xl:py-[54px] px-[16px] xl:px-[72px] rounded-xl">
      <div className="xl:w-[500px] flex flex-col xl:flex-row xl:items-center gap-[24px] pb-[24px] xl:pb-0">
        <img
          src={isSuccess ? data.results[0].signedUrl : ""}
          alt={instructor.firstName}
          className="w-[148px] h-[148px] rounded-full aspect-square object-cover"
        />
        <p className="font-inriaSerif italic text-primary text-[20px] xl:text-[24px] w-fit">
          {instructor.firstName} {instructor.lastName}
        </p>
      </div>
      <div className="w-full xl:w-[calc(100%-80px-72px)] xl:ml-[72px]">
        <div
          className="text-[16px] space-y-4"
          dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
        ></div>
      </div>
    </div>
  );
};
