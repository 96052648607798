import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { useContext } from "react";
import { ListingContext } from "../../../../../contexts/listing";
import Divider from "../../UI/Divider/Divider";
import SectionTitle from "../../UI/SectionTitle/SectionTitle";

// Schedule section component
// Maps overs the days included in the schedule and sends the information to DailyScheduleCard
export default function Schedule({ content }) {
  const { listingData } = useContext(ListingContext);

  return (
    <>
      <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="Schedule" />
        <div className="flex flex-col gap-[16px]">
          {listingData.eventData[0].itineraryDayByDay.map((content, idx) => (
            <DailyScheduleCard key={idx} day={idx} schedule={content} />
          ))}
        </div>
      </section>
      <Divider />
    </>
  );
}

// Daily schedule card component
// Maps over each days' schedule and displays the itinerary in a bulleted list
const DailyScheduleCard = ({ day, schedule }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: schedule,
  });

  return (
    <div className="flex flex-col lg:flex-row lg:items-center gap-[4px] lg:gap-[40px]">
      <p className="w-[100px] h-fit bg-background2 font-inriaSerif italic text-background rounded-md text-center text-[20px] lg:text-[24px]">
        Day {day + 1}
      </p>
      <div className="w-full border border-background2 rounded-md p-[16px]">
        <div
          className="space-y-4"
          dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
        ></div>
      </div>
    </div>
  );
};
