import { useContext } from "react";
import { ListingContext } from "../../../../../contexts/listing";
import { LocationSVG } from "../SVG/svg";

export default function HeroCard({ content }) {
  const { listingData } = useContext(ListingContext);

  const findLooseAddress = (addressComponents) => {
    const looseAddress = addressComponents.find(
      (ac) =>
        ac.types.includes("administrative_area_level_1") ||
        ac.types.includes("administrative_area_level_2") ||
        ac.types.includes("country")
    );
    return looseAddress?.long_name;
  };

  return (
    <div className="flex flex-col bg-white w-fit lg:w-[600px] h-fit rounded-md px-[12px] sm:px-[16px] py-[16px] lg:px-[32px] lg:py-[24px] gap-[16px]">
      <h1 className="font-inriaSerif italic text-primary text-[24px] lg:text-[36px] text-balance">
        {listingData.name}
      </h1>
      <div className="flex gap-[8px] pb-[8px]">
        <p className="text-primary text-[14px] font-bold">
          By {listingData.merchantAccount.legalEntityName}
        </p>
      </div>
      <div className="flex gap-[8px] pb-[8px]">
        <LocationSVG />
        <p className="text-textSecondary text-[14px]">
          {findLooseAddress(
            listingData.accommodationData[0].location.addressComponents
          )}
        </p>
      </div>
      {/* <div className="flex items-center gap-[8px]">
        <div className="w-fit h-fit bg-background2 rounded-full flex gap-[4px] p-[6px] text-white text-[12px]">
          <WhiteStarSVG />
          <span>Best price guarantee</span>
        </div>
        <div className="flex gap-[4px] items-center pl-[8px]">
          <span className="font-semibold text-text text-[16px]">
            {content.rating}
          </span>
          <StarSVG />
        </div>
        <p className="text-textSecondary text-[12px] underline decoration-primary">{`(${content.reviewCount} reviews)`}</p>
      </div> */}
    </div>
  );
}
