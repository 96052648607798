import { generateHTML, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import Dinero from "dinero.js";

import Divider from "../UI/Divider/Divider";
import GalleryCover from "../UI/GalleryCover/GalleryCover";
import SectionTitle from "../UI/SectionTitle/SectionTitle";
import StickyBookBarMobile from "../UI/StickyBookBarMobile/StickyBookBarMobile";
import Accommodation from "./Accommodation/Accommodation";
import Instructors from "./Instructors/Instructors";
import MoreInformation from "./MoreInformation/MoreInformation";
import Schedule from "./Schedule/Schedule";

import React, { useContext } from "react";
import { ListingContext } from "../../../../contexts/listing";
import { useGetMediaQuery } from "../../../../services/rtk/osare";
import {
  accommodation,
  beforeYouGo,
  cancellationPolicy,
  food,
  includedItems,
  location,
  moreInformation,
  program,
  retreatDetails,
  schedule,
  whatToBring,
  whatToExpect,
} from "../../figma-sample-data";
import { Circle } from "../UI/Map/Circle";
import {
  AdventurersSVG,
  CheckmarkSVG,
  CloseSVG,
  FriendsSVG,
  HighlightsSVG,
  NatureSVG,
} from "../UI/SVG/svg";

// This section contains all the components that build up the retreat page
export default function ListingContent() {
  const { listingData } = useContext(ListingContext);

  return (
    <>
      <section className="w-full flex flex-col items-center py-[24px] gap-[8px] lg:gap-0">
        {/* <PerfectFor /> */}
        <div className="z-50 w-full sticky top-0">
          <StickyBookBarMobile />
        </div>
        <RetreatDetails content={retreatDetails} />
        <WhatToExpect content={whatToExpect} />
        <Program content={program} />
        <Schedule content={schedule} />
        <Instructors instructorsData={listingData.staffData} />
        <Location content={location} />
        <Food content={food} />
        <Included content={includedItems} />
        <BeforeYouGo content={beforeYouGo} />
        <WhatToBring content={whatToBring} />
        <MoreInformation content={moreInformation} />
        <CancellationPolicy content={cancellationPolicy} />
        <Accommodation content={accommodation} />
      </section>
    </>
  );
}

// Perfect For section component
const PerfectFor = () => {
  return (
    <>
      <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] xl:items-center gap-[24px]">
        <SectionTitle title="Perfect for" />
        <div className="w-full font-semibold flex justify-center xl:justify-start xl:gap-[100px] text-center">
          <div className="flex flex-col items-center justify-start w-full xl:w-fit">
            <NatureSVG />
            <p className="h-fit">Nature Lovers</p>
          </div>
          <div className="flex flex-col items-center justify-start w-full xl:w-fit">
            <FriendsSVG />
            <p className="h-fit">Making New Friends</p>
          </div>
          <div className="flex flex-col items-center justify-start w-full xl:w-fit">
            <AdventurersSVG />
            <p className="h-fit">Adventurers</p>
          </div>
        </div>
      </section>
      <Divider />
    </>
  );
};

// Retreat Details section component
// Takes in props for duration, groupSize, flightInfo, perfectFor, price, age, skillLevel
const RetreatDetails = ({ content }) => {
  const { listingData } = useContext(ListingContext);

  return (
    <>
      <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="Retreat Details" />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-[16px] gap-x-[64px]">
          <div className="flex flex-col gap-1">
            <p className="font-bold">Duration</p>
            <p>
              {listingData.eventData[0].eventDaysLength} days (
              {listingData.eventData[0].eventDaysLength - 1} nights)
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold">Price</p>
            <p>
              From:{" "}
              {Dinero({
                amount: listingData.minimumPrice * 100,
                currency:
                  listingData.merchantAccount.defaultCurrency.toUpperCase(),
              }).toFormat("$0,0")}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold">Group Size</p>
            <p>
              {listingData.eventData[0].minimumGroupSize} -{" "}
              {listingData.eventData[0].maximumGroupSize}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold">Min / Max Age</p>
            <p>
              {listingData.eventData[0].minimumAge} -{" "}
              {listingData.eventData[0].maximumAge}
            </p>
          </div>
          {/* <div className="flex flex-col gap-1">
            <p className="font-bold">Flight Info</p>
            <p>{content.flightInfo}</p>
          </div> */}
          <div className="flex flex-col gap-1">
            <p className="font-bold">Skill Level</p>
            <p>{listingData.eventData[0].skillLevels.join(", ")}</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold">Spoken Languages</p>
            <p>{listingData.eventData[0].spokenLanguages.join(", ")}</p>
          </div>
          {/* <div className="flex flex-col gap-1">
            <p className="font-bold">Perfect for</p>
            <p>{content.perfectFor}</p>
          </div> */}
          <div className="flex flex-col gap-1">
            <p className="font-bold">Accommodation Style</p>
            <p>{listingData.accommodationData[0].styles.join(", ")}</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold">Retreat Style</p>
            <p>{listingData.eventData[0].categories.join(", ")}</p>
          </div>
        </div>
      </section>
      <Divider />
    </>
  );
};

// What to expect section component
const WhatToExpect = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.introduction,
  });

  return (
    <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
      <SectionTitle title="What to expect" />
      <div className="flex flex-col gap-[32px]">
        <p className="font-inriaSerif italic text-primary text-[20px] lg:text-[24px]">
          {listingData.tagline}
        </p>
        <div className="text-[18px]">
          <div
            className="whitespace-pre-line space-y-4"
            dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
          ></div>
        </div>
        <Highlights items={content.listItems} />
      </div>
    </section>
  );
};

// Highlights section component
const Highlights = ({ items }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.highlights,
  });

  return (
    <div className="bg-white h-fit p-[16px] flex flex-col gap-[24px]">
      <div className="flex items-center gap-[8px]">
        <HighlightsSVG />
        <p className="font-inriaSerif italic text-primary text-[24px]">
          Highlights:
        </p>
      </div>
      <div
        className="flex flex-col gap-[16px] pl-6 text-[16px] pb-[24px] space-y-4"
        dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
      ></div>
    </div>
  );
};

// Program/ Itinerary section component
const Program = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.eventData[0].itineraryOverview,
  });

  return (
    <>
      <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="Program &amp; Itinerary" />
        <div className="flex text-[18px] lg:text-[16px]">
          <div
            className="whitespace-pre-line space-y-4"
            dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
          ></div>
        </div>
      </section>
      <Divider />
    </>
  );
};

// Location section component
const Location = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.accommodationData[0].locationDescription,
  });

  return (
    <>
      <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="Location" />
        <div className="w-full flex flex-col lg:flex-row justify-between gap-[24px]">
          <div
            className="w-full lg:w-[613px] space-y-4"
            dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
          ></div>

          <div className="w-full lg:w-[563px] h-auto lg:h-[342px]">
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <Map
                mapId={"map"}
                defaultCenter={{
                  lat: listingData.accommodationData[0].location.geometry.lat,
                  lng: listingData.accommodationData[0].location.geometry.lng,
                }}
                defaultZoom={10}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
              >
                <Circle
                  center={{
                    lat: listingData.accommodationData[0].location.geometry.lat,
                    lng: listingData.accommodationData[0].location.geometry.lng,
                  }}
                  fillColor={"#f7f6f3"}
                  radius={10000}
                />
              </Map>
            </APIProvider>
            {/* <img
              src={content.image}
              alt="Location"
              className="w-full rounded-md object-cover"
            /> */}
          </div>
        </div>
      </section>
      <Divider />
    </>
  );
};

// Food section component
const Food = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.eventData[0].overallFoodDescription,
  });

  const { data: eventGalleryData } = useGetMediaQuery(
    {
      collection: "food-drink",
      collectionId: listingData.eventData[0]._id,
    },
    {
      skip: !listingData.eventData[0]._id,
    }
  );

  return (
    <>
      <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="Food" />
        <div className="w-full flex flex-col lg:flex-row justify-between gap-[32px]">
          <div className="lg:hidden">
            {eventGalleryData &&
            eventGalleryData.results.length > 0 &&
            eventGalleryData.results[0].signedUrl ? (
              <GalleryCover
                image={eventGalleryData.results[0].signedUrl}
                galleryData={eventGalleryData.results}
              />
            ) : null}
          </div>
          <div className="w-full lg:w-[605px] flex flex-col text-[16px] gap-[24px]">
            <div
              className="whitespace-pre-line space-y-4"
              dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
            ></div>
            <div className="flex flex-col gap-[8px]">
              <p className="font-bold">Included Meals</p>
              <ul className="flex gap-[32px] list-disc pl-6 text-[16px]">
                {listingData.eventData[0].includedMeals.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col gap-[8px]">
              <p className="font-bold">Included Drinks</p>
              <ul className="flex gap-[32px] list-disc pl-6 text-[16px]">
                {listingData.eventData[0].includedDrinks.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col gap-[8px]">
              <p className="font-bold">Diets Catered</p>
              <ul className="flex flex-row list-disc gap-x-[32px] gap-y-[12px] pl-6 text-[16px] flex-wrap">
                {listingData.eventData[0].dietarySupport.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="w-[563px] h-fit hidden lg:flex">
            {eventGalleryData &&
            eventGalleryData.results.length > 0 &&
            eventGalleryData.results[0].signedUrl ? (
              <GalleryCover
                image={eventGalleryData.results[0].signedUrl}
                galleryData={eventGalleryData.results}
              />
            ) : null}
          </div>
        </div>
      </section>
      <Divider />
    </>
  );
};

// What's Included section component
const Included = ({ content }) => {
  const { listingData } = useContext(ListingContext);

  return (
    <>
      <section className="w-full flex flex-col lg:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-0 items-start justify-start">
        <div className="flex flex-col w-full lg:w-1/2 gap-[24px]">
          <SectionTitle title="What's Included" />
          <div className="flex flex-col gap-[8px]">
            {listingData.eventData[0].specificallyIncluded.map(
              (item, index) => (
                <div key={index} className="flex gap-[8px]">
                  <CheckmarkSVG />
                  <span>{item.description}</span>
                </div>
              )
            )}
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2 gap-[24px]">
          <SectionTitle title="What's not Included" />
          <div className="flex flex-col gap-[8px]">
            {listingData.eventData[0].specificallyExcluded.map(
              (item, index) => (
                <div key={index} className="flex gap-[8px]">
                  <CloseSVG />
                  <span>{item.description}</span>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <Divider />
    </>
  );
};

// Before You Go section component
const BeforeYouGo = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  return (
    <>
      <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="Before You Go" />
        <div className="flex flex-col xl:flex-row justify-between gap-[24px]">
          <div className="flex-1 flex-col gap-[12px] xl:gap-[24px]">
            <p className="mb-5 font-bold">Know before you go</p>
            <div
              dangerouslySetInnerHTML={{
                __html: generateHTML(
                  listingData.eventData[0].preTravelAdvisory,
                  [StarterKit]
                ),
              }}
            ></div>
          </div>
          <div className="flex-1 flex-col gap-[12px] xl:gap-[24px]">
            <p className="mb-5 font-bold">Optional Extras</p>
            <div
              dangerouslySetInnerHTML={{
                __html: generateHTML(listingData.eventData[0].thingsToDoPaid, [
                  StarterKit,
                ]),
              }}
            ></div>
          </div>
          <div className="flex-1 flex-col gap-[12px] xl:gap-[24px]">
            <p className="mb-5 font-bold">Seasonal</p>
            <div
              dangerouslySetInnerHTML={{
                __html: generateHTML(
                  listingData.accommodationData[0].seasonalAdvisory,
                  [StarterKit]
                ),
              }}
            ></div>
          </div>
        </div>
      </section>
      <Divider />
    </>
  );
};

// What to Bring section component
const WhatToBring = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.accommodationData[0].whatToBringAdvisory,
  });

  return (
    <>
      <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
        <SectionTitle title="What to Bring" />
        <div
          className=" pl-[24px] flex flex-col gap-[8px] space-y-4"
          dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
        ></div>
      </section>
      <Divider />
    </>
  );
};

// Cancellation Policy section component
const CancellationPolicy = ({ content }) => {
  const { listingData } = useContext(ListingContext);

  return (
    <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
      <SectionTitle title="Cancellation Policy" />
      <div className="flex flex-col gap-[16px]">
        <p className="font-bold">
          Cancellation policy set by {listingData.merchantAccount.businessName}:
        </p>
        <div>
          {listingData.cancellationRanges.map((range, idx) => (
            <p key={idx}>
              {range.day} day{Number(range.day) > 1 ? "s" : ""} after booking
              date: {range.percentage}% refund
            </p>
          ))}
          <p className="mt-4">
            After day {listingData.cancellationRanges.length} from when the
            booking was made, no refunds will be given.
          </p>
        </div>
      </div>
    </section>
  );
};
