import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/joy";
import React from "react";

export default function PaymentNotStarted() {
  return (
    <Card sx={{ mb: 2 }} color="warning">
      <Typography
        startDecorator={<CircularProgress sx={{ mr: 1 }} size="sm" />}
        level="title-lg"
        color="warning"
      >
        We are still processing this booking
      </Typography>

      <CardContent>
        <Typography gutterBottom>
          This order may have just been placed and we are still processing
          payments. Please check back again later - you can also keep an lookout
          for emails which will inform you of any updates to your booking.
        </Typography>

        <Typography>
          If you are seeing this message an hour after you booked please contact
          us for further assistance.
        </Typography>
      </CardContent>

      <CardActions>
        <Button variant="outlined" onClick={() => window.location.reload()}>
          Check now
        </Button>
      </CardActions>
    </Card>
  );
}
