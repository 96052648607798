import { useLocation, useSearchParams } from "react-router-dom";
import { EmailSVG } from "../UI/SVG/svg";

export default function EmailSubscribe() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const handleSubmit = (event) => {
    // Take form data and add to mailing list
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    console.log(formData.get("name"), formData.get("email"));
    event.currentTarget.reset();
    // Show success message?
  };

  if (pathname.includes("my") || searchParams.get("sessionId")) {
    return null;
  }

  return (
    <div className=" w-full bg-white flex flex-col lg:flex-row items-center lg:items-start justify-center gap-[24px] py-[16px] lg:py-[48px] px-[16px] lg:px-[120px] text-text text-[16px] text-center lg:text-start">
      <EmailSVG />
      <div className="w-[345px] flex flex-col gap-[8px]">
        <p className="font-inriaSerif italic text-primary text-[24px]">
          Ready to explore the world sustainably?
        </p>
        <p>Sign up for exclusive travel insights!</p>
      </div>
      <div className="flex flex-col gap-[16px]">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col lg:flex-row items-center gap-[16px]"
        >
          <input
            type="text"
            name="name"
            placeholder="Name"
            className="min-h-[52px] h-fit w-[220px] bg-background text-primaryOutline placeholder-primaryOutline opacity-65 border border-primaryOutline rounded-md text-[18px] py-[12px] px-[16px]"
          />
          <input
            type="email"
            name="email"
            placeholder="Type Your Email"
            className="min-h-[52px] h-fit w-[220px] bg-background text-primaryOutline placeholder-primaryOutline opacity-65 border border-primaryOutline rounded-md text-[18px] py-[12px] px-[16px]"
          />
          <button
            type="submit"
            className="min-h-[48px] h-fit w-fit bg-primaryOutline text-white rounded-md py-[6px] px-[24px] text-center"
          >
            Subscribe Now
          </button>
        </form>
        <p className="text-textTertiary text-[14px] text-balance">
          We take your privacy very seriously. The information you submit to us
          will not be disclosed to others. Thank you.
        </p>
      </div>
    </div>
  );
}
