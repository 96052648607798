import React from "react";

export default function ListingNotFound() {
  return (
    <div className="bg-neutral h-screen flex items-center justify-center">
      <div className="w-96">
        <p className="text-center mb-4 font-semibold">Listing not found!</p>
        <p className="text-center mb-4">
          Please check the link and try again. It may also be possible that this
          listing has now been removed by the owner.
        </p>
      </div>
    </div>
  );
}
