//import sampleData from "./sample-listing-data";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ListingContext } from "../../contexts/listing";
import {
  useCreateCustomerMutation,
  useLazyGetStorefrontListingQuery,
} from "../../services/rtk/osare";
import EmailSubscribe from "./components/EmailSubscribe/EmailSubscribe";
import ListingContent from "./components/ListingContent/ListingContent";
import Footer from "./components/UI/Footer/Footer";
import Header from "./components/UI/Header/Header";
import HeroSection from "./components/UI/HeroSection/HeroSection";
import LoadingFullHeight from "./components/UI/Loading/Loading";
import ListingNotFound from "./components/UI/NotFound/NotFound";

export default function ThemedListing() {
  let [searchParams] = useSearchParams();
  const auth = getAuth();
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [selectedDate, setSelectedDate] = useState(-1);
  const [createCustomer, createCustomerResult] = useCreateCustomerMutation();
  const [customerId, setCustomerId] = useState(false);
  const navigate = useNavigate();
  const [
    getListing,
    {
      isLoading: isListingLoading,
      data: fetchedListingData,
      isSuccess: isListingSuccess,
      error: listingError,
    },
  ] = useLazyGetStorefrontListingQuery();
  const [hasAvailability, setHasAvailability] = useState(false);

  useEffect(() => {
    console.log("listingError", listingError);
    // if (listingError) {
    //   navigate("/404");
    // }
  }, [listingError]);

  useEffect(() => {
    auth.onAuthStateChanged((authResult) => {
      if (!authResult) {
        navigate(
          `/login?returnTo=${encodeURIComponent(
            `/listing?listingId=${searchParams.get("listingId")}`
          )}`
        );
      } else {
        setLoggedInUser(authResult);
        getListing(searchParams.get("listingId"));
      }
    });
  }, [auth, getListing, navigate, searchParams, setLoggedInUser]);

  useEffect(() => {
    if (fetchedListingData && fetchedListingData.results) {
      document.title = `${fetchedListingData.results[0].name} | ${fetchedListingData.results[0].config.name}`;
      createCustomer({
        merchantStripeAccountId:
          fetchedListingData.results[0].merchantAccount.stripeAccountId,
        merchantFirebaseUserId: fetchedListingData.results[0].firebaseUserId,
      });

      /**
       * Is there any availability? Set a flag
       */
      const availabilityCount =
        fetchedListingData.results[0].availabilityData.filter(
          (a) => a.fromTimestamp > Date.now()
        );

      setHasAvailability(availabilityCount.length > 0);
    }
  }, [createCustomer, fetchedListingData]);

  useEffect(() => {
    if (
      createCustomerResult.status === "fulfilled" &&
      createCustomerResult.data
    ) {
      setCustomerId(createCustomerResult.data?.results[0].stripeCustomerId);
    }
  }, [createCustomerResult]);

  return (
    <div>
      {listingError && <ListingNotFound />}

      {isListingLoading && <LoadingFullHeight />}

      {createCustomerResult.isSuccess && isListingSuccess && !listingError && (
        <>
          <ListingContext.Provider
            value={{ listingData: fetchedListingData.results[0] }}
          >
            <Header />
            <HeroSection />
            <div className="font-mulish bg-background text-text h-full flex flex-col items-center text-[14px] lg:text-[16px] px-[16px] lg:px-[120px]">
              <div className="w-full max-w-[1440px]">
                <ListingContent />
              </div>
            </div>
            <EmailSubscribe />
            <Footer />
          </ListingContext.Provider>
        </>
      )}
    </div>
  );
}
