import { useContext } from "react";
import { ListingContext } from "../../../../../contexts/listing";
import { useGetMediaQuery } from "../../../../../services/rtk/osare";
import GalleryCover from "../GalleryCover/GalleryCover";
import HeroCard from "../HeroCard/HeroCard";
import HeroCardExtended from "../HeroCardExtended/HeroCardExtended";

const heroInfo = {
  title: "8 Day Health Retreat: Massage, Yoga & Acupuncture in Bali",
  location: "Wappingers Falls, New York, United States",
  rating: 5.1,
  reviewCount: 4500,
  price: "1,400",
  previousPrice: "1,500",
  deposit: "500",
};

// Displays background cover photo with Hero Card that gets passed data about retreat
export default function HeroSection() {
  const { listingData } = useContext(ListingContext);
  const { data: eventGalleryData } = useGetMediaQuery(
    {
      collection: "events",
      collectionId: listingData.eventData[0]._id,
    },
    {
      skip: !listingData.eventData[0]._id,
    }
  );

  return (
    <div className="w-full relative">
      {eventGalleryData?.results[0]?.signedUrl && (
        <GalleryCover
          image={eventGalleryData.results[0].signedUrl}
          galleryData={eventGalleryData.results}
          forHero
        />
      )}
      {/* <img
        src={eventGalleryData?.results[0]?.signedUrl}
        alt="View of Retreat"
        className="w-full h-[495px] lg:h-fit lg:max-h-[650px] object-cover brightness-75"
      /> */}
      <div className="absolute flex desktopWide:hidden w-fit justify-center lg:justify-start bottom-[16px] lg:bottom-[40px] lg:left-[120px] px-[14.5px] lg:px-0 z-10">
        <HeroCard content={heroInfo} />
      </div>
      <div className="absolute hidden desktopWide:flex w-full h-fit items-center justify-center bottom-[40px] z-10">
        <div className="w-[1440px] flex justify-start">
          <HeroCardExtended content={heroInfo} />
        </div>
      </div>
    </div>
  );
}
