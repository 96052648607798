import { Card, Grid, Typography } from "@mui/joy";
import { DateTime } from "luxon";

export default function BookingDateRange(props) {
  console.log("BookingDateRange", props);
  const availabilityItem = props.orderData.availabilityData;
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <Card>
            <Typography mb={-1} level="body-xs">
              Arrival Date
            </Typography>
            <Typography>
              {DateTime.fromMillis(
                availabilityItem.fromTimestamp
              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
            </Typography>
            <Typography mb={-1} level="body-xs">
              Departure Date
            </Typography>
            <Typography>
              {DateTime.fromMillis(availabilityItem.toTimestamp).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card>
            <Typography mb={-1} level="body-xs">
              Check-in Time
            </Typography>
            <Typography>
              {props.orderData.listingData.eventData[0].checkInTime}
            </Typography>
            <Typography mb={-1} level="body-xs">
              Check-out Time
            </Typography>
            <Typography>
              {props.orderData.listingData.eventData[0].checkOutTime}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
