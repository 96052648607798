import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { GallerySVG } from "../SVG/svg";

//Cover image with absolute positioned button that can be configered to open a gallery
export default function GalleryCover({ image, galleryData, forHero }) {
  console.log("attempting to load image", image);
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full relative">
      {galleryData && (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={galleryData.map((galleryItem) => ({
            src: galleryItem.signedUrl,
          }))}
        />
      )}

      {galleryData && (
        <button
          onClick={() => setOpen(true)}
          className="bg-white w-fit h-fit text-primary flex gap-[6px] py-[8px] px-[16px] rounded-md absolute bottom-[16px] right-[16px] z-20"
        >
          <span>View Gallery</span>
          <span>({galleryData.length})</span>
          <GallerySVG />
        </button>
      )}
      <img
        src={image ? image : "https://placehold.co/600x400"}
        alt={"Gallery view"}
        className={
          forHero
            ? "w-full h-[495px] lg:h-fit lg:max-h-[650px] object-cover brightness-75"
            : "rounded-md object-cover"
        }
      />
    </div>
  );
}
