import { Box, Grid, LinearProgress } from "@mui/joy";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import EmailSubscribe from "../../themes/metimeaway/components/EmailSubscribe/EmailSubscribe";
import Footer from "../../themes/metimeaway/components/UI/Footer/Footer";

export default function WrapperContainer(props) {
  const auth = getAuth();
  const [loggedInUser, setLoggedInUser] = useState(false);

  useEffect(() => {
    const findUser = () => {
      auth.onAuthStateChanged((auth) => {
        if (
          !auth &&
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/listing"
        ) {
          window.location.href = "/login";
        } else {
          setLoggedInUser(auth);
        }
      });
    };

    findUser();
  }, [auth]);

  return (
    <>
      {/* <Header /> */}
      <div className="font-mulish bg-background text-text h-full flex flex-col items-center text-[14px] lg:text-[16px] px-[16px] lg:px-[120px]">
        <div className="w-full max-w-[1440px]">
          <Grid container mb={5}>
            <Grid md={3}></Grid>
            <Grid xs={12} md={6}>
              {loggedInUser ? (
                props.children
              ) : (
                <Box textAlign={"center"}>
                  <LinearProgress />
                </Box>
              )}
            </Grid>
            <Grid md={3}></Grid>
          </Grid>
        </div>
      </div>
      <EmailSubscribe />
      <Footer />
    </>
  );
}
