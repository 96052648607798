import { Check, Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Modal,
  ModalDialog,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import { PaymentIcon } from "react-svg-credit-card-payment-icons";
import ResponsiveAppBar from "../../../components/Appbar/Appbar";
import WrapperContainer from "../../../components/WrapperContainer/WrapperContainer";
import {
  useCreateUserSetupIntentMutation,
  useDeletePaymentMethodMutation,
  useGetCustomerPaymentMethodsQuery,
  usePutDefaultPaymentMethodMutation,
} from "../../../services/rtk/osare";

export default function MyPaymentMethods() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
  const [paymentWidgetState, setPaymentWidgetState] = useState({});
  const [isPaymentWidgetLoading, setIsPaymentWidgetLoading] = useState(false);
  const [latestDefaultPaymentId, setLatestDefaultPaymentId] = useState(null);
  const [fireSetupIntent] = useCreateUserSetupIntentMutation();
  const [putDefaultPaymentMethod] = usePutDefaultPaymentMethodMutation();
  const {
    data: paymentMethodData,
    refetch: paymentMethodRefetch,
    isError: paymentMethodIsError,
    isLoading: paymentMethodIsLoading,
  } = useGetCustomerPaymentMethodsQuery();
  const [isDetatchPaymentModalOpen, setIsDetatchPaymentModalOpen] =
    useState(false);
  const [isPaymentMethodOptionsOpen, setIsPaymentMethodOptionsOpen] =
    useState(false);

  useEffect(() => {
    if (paymentMethodIsError) {
      setTimeout(() => {
        paymentMethodRefetch();
      }, 2000);
    }
  }, [paymentMethodIsError, paymentMethodRefetch]);

  useEffect(() => {
    if (paymentMethodData) {
      // Find the latest default payment method
      setLatestDefaultPaymentId(
        paymentMethodData.customer.invoice_settings.default_payment_method
      );
    }
  }, [paymentMethodData]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsPaymentWidgetLoading(true);
    const submitResult = await elements.submit();
    console.log("submitResult", submitResult);

    const result = await stripe.createPaymentMethod({
      elements,
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setIsPaymentWidgetLoading(false);
    } else {
      console.log("new payment method!", result);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      const setupIntentResult = await fireSetupIntent({
        paymentMethodId: result.paymentMethod.id,
      })
        .unwrap()
        .catch((e) => {
          console.error("Error creating setup intent", e);
          setIsPaymentWidgetLoading(false);
          return null;
        });

      console.log("setupIntentResult", setupIntentResult);

      await stripe
        .confirmSetup({
          clientSecret: setupIntentResult.client_secret,
          confirmParams: {
            return_url: `${window.location.href}?completed`,
          },
        })
        .then((result) => {
          console.log("confirmSetup success", result);
        })
        .catch((error) => {
          console.error("confirmSetup error", error);
          setIsPaymentWidgetLoading(false);
        });
    }
  };

  const PaymentMethodOptions = () => {
    return (
      <Modal
        open={isPaymentMethodOptionsOpen}
        onClose={() => setIsPaymentMethodOptionsOpen(false)}
      >
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            What do you want to do with this payment method?
          </Typography>

          {currentPaymentMethod && (
            <Typography
              startDecorator={
                <PaymentIcon
                  type={currentPaymentMethod.card.brand}
                  width={25}
                />
              }
            >
              Last 4 digits: {currentPaymentMethod.card.last4} / Expiry:{" "}
              {currentPaymentMethod.card.exp_month}/
              {currentPaymentMethod.card.exp_year}
            </Typography>
          )}
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button
              onClick={async () => {
                setIsPaymentMethodOptionsOpen(false);
                const key = enqueueSnackbar(
                  "Setting default payment method..."
                );

                await putDefaultPaymentMethod({
                  paymentMethodId: currentPaymentMethod.id,
                })
                  .unwrap()
                  .then(() => {
                    closeSnackbar(key);
                    enqueueSnackbar("Default payment method set", {
                      variant: "success",
                    });

                    paymentMethodRefetch();
                  })
                  .catch((e) => {
                    console.error("Error setting default payment method", e);
                    alert(
                      "Error setting default payment method, please try again."
                    );
                  });
              }}
              color="primary"
            >
              Set as default
            </Button>
            <Button
              onClick={() => {
                setIsDetatchPaymentModalOpen(true);
                setIsPaymentMethodOptionsOpen(false);
              }}
              variant="solid"
              color="danger"
            >
              Delete
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    );
  };

  const DetachPaymentMethod = () => {
    const [detatchPaymentMethod, { isLoading }] =
      useDeletePaymentMethodMutation();

    return (
      <Modal
        open={isDetatchPaymentModalOpen}
        onClose={() => setIsDetatchPaymentModalOpen(false)}
      >
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            Are you sure you want to delete this payment method?
          </Typography>

          {currentPaymentMethod && (
            <Typography
              startDecorator={
                <PaymentIcon
                  type={currentPaymentMethod.card.brand}
                  width={25}
                />
              }
            >
              Last 4 digits: {currentPaymentMethod.card.last4} / Expiry:{" "}
              {currentPaymentMethod.card.exp_month}/
              {currentPaymentMethod.card.exp_year}
            </Typography>
          )}

          <Typography color="danger" startDecorator={<Warning />}>
            This action cannot be undone.
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button
              disabled={isLoading}
              variant="solid"
              color="danger"
              onClick={async () => {
                await detatchPaymentMethod({
                  paymentMethodId: currentPaymentMethod.id,
                })
                  .unwrap()
                  .then(() => {
                    setIsDetatchPaymentModalOpen(false);
                    paymentMethodRefetch();
                  })
                  .catch((e) => {
                    console.error("Error deleting payment method", e);
                    alert("Error deleting payment method, please try again.");
                    setIsDetatchPaymentModalOpen(false);
                  });
              }}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </Button>
            <Button
              disabled={isLoading}
              variant="outlined"
              color="neutral"
              onClick={() => setIsDetatchPaymentModalOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    );
  };

  return (
    <WrapperContainer>
      <ResponsiveAppBar />

      <Typography level="h4" marginBottom={3}>
        Payment Methods{" "}
      </Typography>

      <DetachPaymentMethod />
      <PaymentMethodOptions />

      <Tabs
        sx={{ background: "none" }}
        onChange={(e, v) => {
          if (v === 0) {
            paymentMethodRefetch();
          }
        }}
        aria-label="Payment tabs"
        defaultValue={0}
      >
        <TabList
          sx={{
            overflow: "auto",
            scrollSnapType: "x mandatory",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Tab sx={{ flex: "none", scrollSnapAlign: "start" }}>
            Payment Methods
          </Tab>
          <Tab sx={{ flex: "none", scrollSnapAlign: "start" }}>
            Add New Payment Method
          </Tab>
        </TabList>
        <TabPanel value={0} sx={{ padding: 0 }}>
          <Typography gutterBottom mt={2}>
            Tap on a card to select it as your default payment method.
          </Typography>

          {paymentMethodIsLoading && (
            <Typography
              startDecorator={
                <CircularProgress sx={{ mr: 1 }} color="neutral" size="sm" />
              }
              mt={2}
            >
              Loading payment methods...
            </Typography>
          )}

          {!paymentMethodIsLoading &&
            paymentMethodData?.paymentMethods.length === 0 && (
              <Box mt={2}>
                <Card>
                  <Typography>
                    No payment methods found. You can click on the "Add New
                    Payment Method" tab above.
                  </Typography>
                </Card>
              </Box>
            )}

          <List>
            {paymentMethodData?.paymentMethods.map((paymentMethod) => (
              <ListItem key={paymentMethod.id}>
                <ListItemButton
                  onClick={async () => {
                    setCurrentPaymentMethod(paymentMethod);
                    setIsPaymentMethodOptionsOpen(true);
                  }}
                >
                  <ListItemContent>
                    <Typography
                      fontWeight={
                        paymentMethod.id === latestDefaultPaymentId
                          ? "bold"
                          : "normal"
                      }
                      startDecorator={
                        <Box marginRight={1}>
                          <PaymentIcon
                            type={paymentMethod.card.brand}
                            width={25}
                          />
                        </Box>
                      }
                    >
                      Last 4 digits: {paymentMethod.card.last4} / Expiry:{" "}
                      {paymentMethod.card.exp_month}/
                      {paymentMethod.card.exp_year}
                    </Typography>

                    {paymentMethod.id === latestDefaultPaymentId && (
                      <Typography
                        startDecorator={<Check color="success" />}
                        marginLeft={4.8}
                        color="success"
                      >
                        Default payment method
                      </Typography>
                    )}
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </TabPanel>
        <TabPanel value={1} sx={{ padding: 0 }}>
          <PaymentElement
            onChange={(e) => {
              console.log("PaymentElement onChange", e);
              setPaymentWidgetState(e);
            }}
          />
          <Box mt={2}>
            <Button
              disabled={!paymentWidgetState.complete || isPaymentWidgetLoading}
              fullWidth
              onClick={handleSubmit}
            >
              Add Card
            </Button>
          </Box>
        </TabPanel>
      </Tabs>
    </WrapperContainer>
  );
}
