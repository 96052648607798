import { MapTwoTone } from "@mui/icons-material";
import { Card, Link, Typography } from "@mui/joy";

export default function BookingAccommodation(props) {
  return (
    <>
      {props.roomData ? (
        <Card sx={{ padding: 2 }} variant="outlined">
          <Typography mb={-2}>{props.accommodationData.name}</Typography>
          <Typography level="body-lg">
            {props.inventoryQuantity}x {props.roomData.name}
          </Typography>
          {/* <Typography startDecorator={<LocationOn />}>
            {props.accommodationData.location.formattedAddress}
          </Typography> */}

          <Link
            target="_blank"
            href={`https://maps.google.com/?q=${props.accommodationData.location.formattedAddress}`}
            startDecorator={<MapTwoTone />}
          >
            {props.accommodationData.location.formattedAddress}
          </Link>
          <Link
            level={"body-xs"}
            mt={-1}
            ml={4}
            target="_blank"
            href={`https://maps.google.com/?q=${props.accommodationData.location.formattedAddress}`}
          >
            Click to open in Google Maps
          </Link>
        </Card>
      ) : (
        <Typography>
          No accommodation data - the listing may have been deleted.
        </Typography>
      )}
    </>
  );
}
