import { Check } from "@mui/icons-material";
import { Alert, Box, Card, Divider, Link, Typography } from "@mui/joy";
import Dinero from "dinero.js";
import { DateTime } from "luxon";

export default function PaymentsSchedule(props) {
  const SinglePaymentScheduleItem = (scheduledPaymentData) => {
    return (
      <Box marginBottom={2}>
        <Card>
          <Card
            variant="soft"
            color={
              scheduledPaymentData.status === "succeeded"
                ? "success"
                : "neutral"
            }
            fontWeight={"bold"}
          >
            <Typography
              startDecorator={
                scheduledPaymentData.status === "succeeded" ? <Check /> : ""
              }
            >
              {scheduledPaymentData.purpose === "platformAndDepositFee"
                ? "Platform fee & deposit fee: "
                : "Package fee: "}
              {scheduledPaymentData.status}
            </Typography>
          </Card>

          {scheduledPaymentData.onOrAfterTimestampWasAdjustedToNow && (
            <Alert color="warning">
              This payment date was adjusted because{" "}
              {props.listingData.merchantAccount.businessName}
              set a policy that charges you the remaining package fee before the
              date of booking, so this charge was moved up to the date of
              booking.
            </Alert>
          )}

          <Typography marginBottom={-2} fontSize={21} fontFamily={"monospace"}>
            {Dinero({
              amount: scheduledPaymentData.chargeAmount * 100,
              currency:
                props.listingData.merchantAccount.defaultCurrency.toUpperCase(),
            }).toFormat("$0,0.00")}
          </Typography>
          <Typography>
            {scheduledPaymentData.status === "succeeded" && "Paid on: "}
            {scheduledPaymentData.status === "refunded" && "Paid back on: "}
            {scheduledPaymentData.status === "pending" && "Due on: "}
            {scheduledPaymentData.status === "cancelled" && "Was due on: "}
            {DateTime.fromMillis(
              scheduledPaymentData.onOrAfterTimestamp
            ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
          </Typography>

          {scheduledPaymentData.refundedAmount > 0 && (
            <Typography
              color="success"
              marginBottom={-2}
              fontSize={21}
              fontFamily={"monospace"}
            >
              Refunded: GBP {scheduledPaymentData.refundedAmount}
            </Typography>
          )}

          {scheduledPaymentData.receiptUrl ? (
            <Typography>
              <Link href={scheduledPaymentData.receiptUrl} target="_blank">
                View Receipt
              </Link>
            </Typography>
          ) : null}
        </Card>
      </Box>
    );
  };

  return (
    <>
      <Box>
        <Divider>Payments</Divider>

        {props.scheduledPayments.length === 0 && (
          <Typography
            my={3}
            textAlign={"center"}
            level="body-sm"
            color="neutral"
          >
            No payments have been scheduled yet. If you have just booked this,
            the payments will appear here soon.
          </Typography>
        )}

        {props.scheduledPayments.map((payment) => (
          <SinglePaymentScheduleItem key={payment._id} {...payment} />
        ))}
      </Box>
    </>
  );
}
