// https://redux-toolkit.js.org/tutorials/rtk-query#create-an-api-service

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "firebase/auth";

const baseUrl = process.env.REACT_APP_PUBLIC_PLATFORM_API;
const entity = "metimeaway";

export const osareApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      const auth = getAuth();
      if (!auth.currentUser) {
        console.warn("No user found!");
        return;
      }
      const jwt = await auth.currentUser.getIdToken().catch((e) => {
        console.error("Error getting JWT", e);

        return e;
      });

      if (jwt) {
        headers.set("authorization", `Bearer ${jwt}`);
      } else {
        console.warn("No JWT found!");
      }
    },
  }),
  endpoints: (build) => ({
    getMedia: build.query({
      query: (opts) => ({
        url: `storefront/media?entity=${entity}&collection=${opts.collection}&collectionId=${opts.collectionId}`,
      }),
    }),
    getStorefrontUserOrders: build.query({
      query: () => ({
        url: `storefront/user/orders?entity=${entity}`,
      }),
    }),
    getStorefrontUserOrderListings: build.query({
      query: () => ({
        url: `storefront/user/orders/listings?entity=${entity}`,
      }),
    }),
    getStorefrontUserOrderById: build.query({
      query: (opts) => ({
        url: `storefront/user/orders?entity=${entity}&id=${opts.id}`,
      }),
    }),
    getStorefrontListing: build.query({
      query: (id) => ({
        url: `storefront/listings?entity=${entity}&listingId=${id}`,
      }),
    }),
    getAccount: build.query({
      query: () => ({
        url: `accounts?entity=${entity}`,
      }),
    }),
    createCustomer: build.mutation({
      query: (dataObject) => ({
        url: `customers?entity=${entity}`,
        method: "POST",
        body: {
          ...dataObject,
        },
      }),
    }),
    getCustomer: build.query({
      query: () => ({
        url: `customers?entity=${entity}`,
      }),
    }),
    getCustomerPaymentMethods: build.query({
      query: () => ({
        url: `storefront/user/payment-methods?entity=${entity}`,
      }),
    }),
    deletePaymentMethod: build.mutation({
      query: (dataObject) => ({
        url: `storefront/user/payment-methods/detatch?entity=${entity}`,
        method: "DELETE",
        body: {
          ...dataObject,
        },
      }),
    }),
    putDefaultPaymentMethod: build.mutation({
      query: (dataObject) => ({
        url: `storefront/user/payment-methods/default?entity=${entity}`,
        method: "PUT",
        body: {
          ...dataObject,
        },
      }),
    }),
    createCheckoutSession: build.mutation({
      query: (dataObject) => ({
        url: `checkout-session?entity=${entity}`,
        method: "POST",
        body: {
          ...dataObject,
        },
      }),
    }),
    createSetupIntent: build.mutation({
      query: (dataObject) => ({
        url: `checkout-session/setup-intent?entity=${entity}`,
        method: "POST",
        body: {
          ...dataObject,
        },
      }),
    }),
    createCustomerSession: build.mutation({
      query: (dataObject) => ({
        url: `storefront/user/customer-session?entity=${entity}`,
        method: "POST",
        body: {
          ...dataObject,
        },
      }),
    }),

    createUserSetupIntent: build.mutation({
      query: (dataObject) => ({
        url: `storefront/user/setup-intent?entity=${entity}`,
        method: "POST",
        body: {
          ...dataObject,
        },
      }),
    }),

    getCancellationQuote: build.query({
      query: (dataObject) => ({
        url: `storefront/user/orders/cancel/quote?entity=${entity}&orderId=${dataObject.orderId}`,
      }),
    }),
    executeCancellation: build.mutation({
      query: (dataObject) => ({
        url: `storefront/user/orders/cancel/execute?entity=${entity}&quoteId=${dataObject.quoteId}`,
        method: "POST",
      }),
    }),

    doCancellation: build.mutation({
      query: (dataObject) => ({
        url: `storefront/user/orders/cancel?entity=${entity}&orderId=${dataObject.orderId}`,
        method: "POST",
      }),
    }),

    cmsDocumentById: build.query({
      query: (dataObj) => ({
        url: `cms/document?entity=metimeaway&id=${dataObj.id}`,
        method: "GET",
      }),
    }),
    getConfig: build.query({
      query: () => ({
        url: `configs?entity=${entity}`,
      }),
    }),
  }),
});

export const {
  usePutDefaultPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetCustomerPaymentMethodsQuery,
  useLazyGetCustomerPaymentMethodsQuery,
  useCreateUserSetupIntentMutation,
  useCreateCustomerSessionMutation,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useLazyGetStorefrontUserOrdersQuery,
  useLazyGetCancellationQuoteQuery,
  useLazyGetStorefrontUserOrderByIdQuery,
  useLazyGetStorefrontUserOrderListingsQuery,
  useLazyGetStorefrontListingQuery,
  useGetStorefrontUserOrdersQuery,
  useGetStorefrontListingQuery,
  useGetAccountQuery,
  useCreateCustomerMutation,
  useCreateCheckoutSessionMutation,
  useGetMediaQuery,
  useGetCancellationQuoteQuery,
  useExecuteCancellationMutation,
  useDoCancellationMutation,
  useGetStorefrontUserOrderByIdQuery,
  useGetStorefrontUserOrderListingsQuery,
  useCmsDocumentByIdQuery,
  useGetConfigQuery,
  useCreateSetupIntentMutation,
} = osareApi;
