import { WarningSVG } from "../SVG/svg";

// Warning Message text can be customized
export default function WarningMessage({ message }) {
  return (
    <div className="w-full border border-primaryOutline rounded-md flex gap-[16px] items-start justify-start p-[16px]">
      <div className="w-fit=">
        <WarningSVG />
      </div>
      <p className="text-primaryOutline text-[16px] font-light">{message}</p>
    </div>
  );
}
