import { InfoRounded, Warning } from "@mui/icons-material";
import {
  Button,
  Card,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useDoCancellationMutation } from "../../services/rtk/osare";

export default function CancellationModal(props) {
  // console.log("CancellationModal", props);
  // console.log(DateTime.fromMillis(props.state.createdAt).diffNow().as("days"));
  const [triggerDoCancellation, doCancellationResults] =
    useDoCancellationMutation();

  const askToConfirmCancellation = () => {
    if (
      window.confirm(
        "Are you sure you want to cancel this booking? This action cannot be undone!"
      )
    ) {
      triggerDoCancellation({ orderId: props.state._id });
    }
  };

  useEffect(() => {
    if (doCancellationResults.isSuccess) {
      props.openFn(false);
      props.orderRefetchFn();
    }
    if (doCancellationResults.isError) {
      alert(
        `Sorry, an error occured whilst trying to cancel the booking:\n\n${doCancellationResults.error.data.errors.join(
          ", "
        )}\n\nPlease try again, or contact our support team if the problem persists.`
      );
    }
  }, [doCancellationResults, props]);

  const getHighestCancellationRangeDay = () => {
    let highestDay = 0;
    props.state.listingData.cancellationRanges.forEach((cr) => {
      if (Number(cr.day) > highestDay) {
        highestDay = Number(cr.day);
      }
    });
    return highestDay;
  };

  const ShowCancellationOptions = () => {
    return (
      <DialogContent>
        <Typography>
          We're sorry you're looking to cancel your booking. Here are the
          cancellation refund options that{" "}
          {props.state.listingData.merchantAccount.businessName} set for this
          booking:
        </Typography>
        <hr />
        {props.state.listingData.cancellationRanges.map((range, idx) => (
          <Typography key={idx}>
            {range.day} day{Number(range.day) > 1 ? "s" : ""} after booking
            date: {range.percentage}% refund
          </Typography>
        ))}
        <Typography mb={2} color="warning" startDecorator={<InfoRounded />}>
          After day {props.state.listingData.cancellationRanges.length} from
          when the booking was made, no refunds will be given.
        </Typography>

        {Math.abs(
          DateTime.fromMillis(props.state.createdAt).diffNow().as("days")
        ) > getHighestCancellationRangeDay() ? (
          <Card color="danger" variant="soft">
            <Typography startDecorator={<Warning />}>
              This order was made on{" "}
              {DateTime.fromMillis(props.state.createdAt).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}{" "}
              which was{" "}
              {DateTime.fromMillis(props.state.createdAt).toRelative()}
            </Typography>
            <Typography>
              You can cancel the booking, but unfortunately the cancellation
              policy that {props.state.listingData.merchantAccount.businessName}{" "}
              set won't allow you to be eligible for a refund as the
              cancellation period now has passed.
            </Typography>
            <Button color="danger" onClick={() => askToConfirmCancellation()}>
              Cancel Booking
            </Button>
          </Card>
        ) : (
          <>
            <Card variant="soft" color="primary">
              <div>
                <Typography gutterBottom fontWeight={"bold"}>
                  This order was made on{" "}
                  {DateTime.fromMillis(props.state.createdAt).toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                  )}{" "}
                  which was{" "}
                  {DateTime.fromMillis(props.state.createdAt).toRelative()} .
                </Typography>
                <Typography>
                  You are within the cancellation period and can cancel this
                  booking according to the refund policy set by{" "}
                  {props.state.listingData.merchantAccount.businessName}.
                </Typography>
              </div>
              <Button
                color="danger"
                variant="soft"
                onClick={() => askToConfirmCancellation()}
              >
                Cancel Booking
              </Button>
            </Card>
          </>
        )}
      </DialogContent>
    );
  };

  return (
    <Modal onClose={() => props.openFn(false)} open={props.open}>
      <ModalDialog
        sx={(theme) => ({
          [theme.breakpoints.only("xs")]: {
            top: "unset",
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: "none",
            maxWidth: "unset",
          },
        })}
      >
        <ModalClose />
        <DialogTitle>Cancellation Options</DialogTitle>

        <ShowCancellationOptions />
      </ModalDialog>
    </Modal>
  );
}
