/* Sample Data that I took directly from the Figma design to use as placeholder text while I develop */

// Sample Data being imported to list in Retreat Details section
export const retreatDetails = {
  duration: "6 days, 5 Nights",
  groupSize: "6 Guests",
  flightInfo: "Funchal Airport",
  perfectFor: "Nature lovers / Adventurers / Making new friends",
  price: "From £1,400 per person £500 deposit",
  age: "18-65",
  skillLevel: "Beginner, Intermediate, Advanced",
};

// Sample Data being imported to list in What to expect section
export const whatToExpect = {
  heading:
    "8 days health regeneration retreat: massage, yoga, acupuncture, hydrogen anti-aging treatment",
  paragraph: ` Boost your vitality, cleanse your body, and clear your mind with the Health Regeneration retreat. 
            
            We have prepared a unique program for a dedicated health retreat, made to prolong youth and health, cell regeneration and total renewal of your body. 
            
            Collecting wisdom from around the world we offer you a special complex of healing techniques and treatments from Balinese, Chinese, Vedic and Eastern European cultures. 
            
            Get away from the bustling life to heal and recenter.
            `,
  listItems: [
    `5 min walk to the beach, bike path along sunset beach`,
    "4 yoga sessions, daily special activities, specialized workshops",
    "1 stand up classes from the river to the ocean",
    "Welcome and farewell blessing",
    "4 nights accommodation",
    "1 session of Infrared sauna and Ice Bath",
    "Daily breakfast and dinner",
  ],
};

// Sample Data being imported to list under Program / Itinerary section
export const program = {
  paragraph: ` Moaina Retreat is the ideal escape for relaxation, inspiration, and personal growth. The goal is to help you feel strong, relaxed, and joyful. They offer physical, emotional, and spiritual well-being through nutrition, yoga, ocean activities, breathwork, meditation, hiking, tarot reading, and Hawaiian culture.

This retreat was designed so you may have a great mix of experiences. From daily yoga practice to Stand-up paddle classes, hikes to waterfalls and sacred places, and Hawaiian cultural activities, they ensure an unforgettable experience! This is a perfect opportunity to experience Hawaii solo or with your friends! This retreat was designed so you may have more freedom in your experience, but you will feel safe to explore the magical North Shore biking in the 7 miracle bike path in front of the house or snorkel at the famous Sharks Cove spot.

If you are looking to relax, and connect while having fun, this experience is for you! In this intimate retreat, you will spend time in nature, and indulge in transformative yoga practices and island activities that will purify your soul and connect you with the simple Polynesian values of life.

If you never paddled before, you will be taught all the basics and ensure a fun and safe activity.

            `,
};

// Sample Data being used for the daily schedule. Taken directly from the Figma design
export const schedule = [
  {
    day: 1,
    schedule: [
      "Pick up at Honolulu airport (afternoon)",
      "Check into accommodation",
      "Explore the local area and get acquainted with the surroundings (bike ride and area map available and free)",
      "Enjoy a relaxing evening on the beach",
      "Dinner",
    ],
  },
  {
    day: 2,
    schedule: [
      "Breakfast",
      "Morning yoga session at the Sky Deck",
      "Welcome blessing and intention setting",
      "Beach time",
      "Tour Haleiwa city",
      "Sunset Stand-up paddle at Haleiwa River",
      "Dinner",
    ],
  },
  {
    day: 3,
    schedule: [
      "Breakfast",
      "Adventure morning to waterfall and stand-up paddle on the river to the ocean (east side of the island)",
      "Dinner",
    ],
  },
  {
    day: 4,
    schedule: [
      "Breakfast",
      "Morning walking and outdoor yoga session by the ocean",
      "Free time at the beach: Choose from stand-up paddle, bike and diving gear, surfing, or just relaxing at the beach",
      "Hiking at Waimea Valley and waterfall",
      "Farewell dinner",
      "Closing circle",
    ],
  },
  {
    day: 5,
    schedule: [
      "Morning sculpt yoga session at the Sky Deck (optional)",
      "Breakfast",
      "Farewell blessing",
      "Check out of accommodation",
      "Honolulu airport drop off (morning)",
    ],
  },
];

// Sample Data being used for the daily schedule. Taken directly from the Figma design. Photos are stored in public/sampleImages
export const instructors = [
  {
    name: "Vivi Campello",
    image: "/sampleImages/vivi.jpg",
    bio: "Vivi Campello is a Brazilian actress, journalist, yoga teacher, tarot reader, and single mother of twins who fell in love with Hawaii and the spirit of Aloha, 12 years ago when she moved to Oahu. The simple lifestyle, the beauty of the mountains, rivers, waterfalls, and oceans, and a deep reverence for the Hawaiian people and their ancient culture became her spiritual practice and way of living. She is passionate about ocean activity, hikes, and waterfalls. She truly believes guided practice in nature can bring more presence and simple joy to your lives.",
  },
  {
    name: "Callie Smith",
    image: "/sampleImages/callie.jpg",
    bio: "Callie led the Haku Lei or Lei Po'o workshop, her passion for working with flowers and teaching the art of creating Hawaiian flower adornments (Lei Po'o) is truly special. With her background in horticulture, specializing in organic crop production, and a minor in entomology, she likely has a deep appreciation for the intricacies of the natural world and the importance of sustainability in the floral industry. Her love for flowers as a means of non-verbal communication and emotional expression is both beautiful and profound.",
  },
];

// Sample data to display room info, taken directly from Figma design
export const accommodation = {
  checkIn: "15:00",
  checkOut: "11:00",
  description:
    " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus auctor velit faucibus consectetur. Aliquam ac est a sem mattis interdum non nec arcu. Sed tristique nec lacus bibendum condimentum. In hac habitasse platea dictumst.  Donec sodales dignissim orci, eu suscipit odio venenatis et. Aenean tincidunt aliquet nunc eu viverra. Nam posuere quam eu fringilla lacinia. Duis pretium arcu massa, vitae molestie orci tempus id. Integer mollis egestas dolor. Mauris vitae sapien sed massa hendrerit ullamcorper ac sit amet metus.  Maecenas laoreet euismod rhoncus. Vestibulum ut dignissim urna. Suspendisse porta vehicula pulvinar. Morbi ut ipsum porttitor, ullamcorper ante sed, tincidunt risus. Nam at sagittis dui. Vestibulum eget tellus pellentesque lacus.",
  rooms: [
    {
      name: "Jade Room",
      price: "£1,400",
      image: "/sampleImages/jadeRoom.jpg",
      description: `Donec sodales dignissim orci, eu suscipit odio venenatis et. Aenean tincidunt aliquet nunc eu viverra. 

    Nam posuere quam eu fringilla lacinia. Duis pretium arcu massa, vitae molestie orci tempus id. Integer mollis egestas dolor. Mauris vitae sapien sed massa hendrerit ullamcorper ac sit amet metus. 

    Maecenas laoreet euismod rhoncus. Vestibulum ut dignissim urna. Suspendisse porta vehicula pulvinar. Morbi ut ipsum porttitor, ullamcorper ante sed, tincidunt risus. Nam at sagittis dui. Vestibulum eget tellus pellentesque lacus.`,
    },
    {
      name: "Jade Room",
      price: "£1,400",
      image: "/sampleImages/jadeRoom.jpg",
      description: `Donec sodales dignissim orci, eu suscipit odio venenatis et. Aenean tincidunt aliquet nunc eu viverra. 

    Nam posuere quam eu fringilla lacinia. Duis pretium arcu massa, vitae molestie orci tempus id. Integer mollis egestas dolor. Mauris vitae sapien sed massa hendrerit ullamcorper ac sit amet metus. 

    Maecenas laoreet euismod rhoncus. Vestibulum ut dignissim urna. Suspendisse porta vehicula pulvinar. Morbi ut ipsum porttitor, ullamcorper ante sed, tincidunt risus. Nam at sagittis dui. Vestibulum eget tellus pellentesque lacus.`,
    },
  ],
};

// Sample text (lorem ipsum) being imported to list under Location section
export const location = {
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus auctor velit faucibus consectetur. Aliquam ac est a sem mattis interdum non nec arcu. Sed tristique nec lacus bibendum condimentum. In hac habitasse platea dictumst. Donec sodales dignissim orci, eu suscipit odio venenatis et. Aenean tincidunt aliquet nunc eu viverra. Nam posuere quam eu fringilla lacinia. Duis pretium arcu massa, vitae molestie orci tempus id. Integer mollis egestas dolor. Mauris vitae sapien sed massa hendrerit ullamcorper ac sit amet metus. Maecenas laoreet euismod rhoncus. Vestibulum ut dignissim urna. Suspendisse porta vehicula pulvinar. Morbi ut ipsum porttitor, ullamcorper ante sed, tincidunt risus. Nam at sagittis dui. Vestibulum eget tellus pellentesque lacus.",
  image: "/sampleImages/location.png",
};

// Sample data to display food info, taken directly from Figma design
export const food = {
  description: `We are a plant based house hold that can easily and happily share plant based foods for all meals.

Vegan/Vegetarian/GlutenFree/NutFree welcome :)

Please let us know about any allergies or sensitive s you have about ingredients before you arrive.`,
  meals: ["Breakfast", "Lunch", "Dinner", "Snacks"],
  drinks: ["Water", "Coffee", "Tea"],
  diets: [
    "Dairy-free",
    "Diabetic-friendly",
    "Egg-free",
    "Halal",
    "Kosher",
    "Low fat",
    "Nut-allergy-friendly",
    "Organic",
    "Raw",
    "Sugar conscious",
    "Vegan",
    "Vegetarian",
  ],
  coverImage: "/sampleImages/food.jpg",
};

// Sample data to display whats included and whats not included, taken directly from Figma design
export const includedItems = {
  included: [
    "2 nights accommodation",
    "Daily yoga classes",
    "3 daily delicious and nutritious meals",
    "Transportation during the retreat",
    "1 hour treatment",
    "Welcome drink",
    "Water, tea, coffee served throughout the day",
    "Nature hike with a trusted guide",
    "Specialized workshops",
    "Pre and post retreat support from our team",
    "Parking",
    "Luggage storage",
    "Wifi connection",
    "Group excursions/activities",
  ],
  notIncluded: [
    "Airport transfer",
    "Travel insurance",
    "Flights costs",
    "Visa fee",
    "Additional activities not included in the itinerary",
    "Additional treatments",
  ],
};

// Sample data to display arrival information, taken directly from Figma design
export const beforeYouGo = {
  arrivalByTrain:
    "Lorem ipsum dolor sit amet consectetur. Eget sed massa consequat pulvinar dis enim sodales cursus. Nulla id purus in quam purus a. Nisi enim semper pulvinar orci ac lorem egestas tincidunt. Fusce nam eget sit ac feugiat est tristique velit. At ultrices mauris massa viverra a. Nulla orci id velit tincidunt felis leo. Orci imperdiet quam porttitor ut velit adipiscing rhoncus tortor. Posuere turpis facilisi imperdiet suscipit mollis. Est elementum eget blandit neque nisi aliquam velit sapien. Amet quam sollicitudin tellus lobortis posuere morbi sagittis nibh. Nulla eu eget varius vitae et ac quis sit sed. Viverra vel lobortis nunc in iaculis vitae sed consectetur. Arcu nunc ut hac dolor nisi cursus. Platea nisl enim adipiscing laoreet a.",
  arrivalByPlane:
    "Lorem ipsum dolor sit amet consectetur. Eget sed massa consequat pulvinar dis enim sodales cursus. Nulla id purus in quam purus a. Nisi enim semper pulvinar orci ac lorem egestas tincidunt. Fusce nam eget sit ac feugiat est tristique velit. At ultrices mauris massa viverra a. Nulla orci id velit tincidunt felis leo. Orci imperdiet quam porttitor ut velit adipiscing rhoncus tortor. Posuere turpis facilisi imperdiet suscipit mollis. Est elementum eget blandit neque nisi aliquam velit sapien. Amet quam sollicitudin tellus lobortis posuere morbi sagittis nibh. Nulla eu eget varius vitae et ac quis sit sed. Viverra vel lobortis nunc in iaculis vitae sed consectetur. Arcu nunc ut hac dolor nisi cursus. Platea nisl enim adipiscing laoreet a.",
  arrivalByCar:
    "Lorem ipsum dolor sit amet consectetur. Eget sed massa consequat pulvinar dis enim sodales cursus. Nulla id purus in quam purus a. Nisi enim semper pulvinar orci ac lorem egestas tincidunt. Fusce nam eget sit ac feugiat est tristique velit. At ultrices mauris massa viverra a. Nulla orci id velit tincidunt felis leo. Orci imperdiet quam porttitor ut velit adipiscing rhoncus tortor. Posuere turpis facilisi imperdiet suscipit mollis. Est elementum eget blandit neque nisi aliquam velit sapien. Amet quam sollicitudin tellus lobortis posuere morbi sagittis nibh. Nulla eu eget varius vitae et ac quis sit sed. Viverra vel lobortis nunc in iaculis vitae sed consectetur. Arcu nunc ut hac dolor nisi cursus. Platea nisl enim adipiscing laoreet a.",
};

// Sample data to show list of what to bring, taken directly from Figma design
export const whatToBring = [
  "Yoga Mat",
  "2 yoga blocks",
  "1 yoga blanket",
  "1 yoga strap ( 8ft +)",
  "Eye pillow",
  "bolster (optional)",
  "Water bottle",
  "Hiking boots",
  "Journal",
];

// Sample data to show weather, insurance, vaccination, and visa info taken directly from Figma design
export const moreInformation = {
  weather:
    "Lorem ipsum dolor sit amet consectetur. Eget sed massa consequat pulvinar dis enim sodales cursus. Nulla id purus in quam purus a. Nisi enim semper pulvinar orci ac lorem egestas tincidunt. Fusce nam eget sit ac feugiat est tristique velit. At ultrices mauris massa viverra a. Nulla orci id velit tincidunt felis leo. Orci imperdiet quam porttitor ut velit adipiscing rhoncus tortor. Posuere turpis facilisi imperdiet suscipit mollis. Est elementum eget blandit neque nisi aliquam velit sapien. Amet quam sollicitudin tellus lobortis posuere morbi sagittis nibh. Nulla eu eget varius vitae et ac quis sit sed. Viverra vel lobortis nunc in iaculis vitae sed consectetur. Arcu nunc ut hac dolor nisi cursus. Platea nisl enim adipiscing laoreet a.",
  insurance:
    "Lorem ipsum dolor sit amet consectetur. Eget sed massa consequat pulvinar dis enim sodales cursus. Nulla id purus in quam purus a. Nisi enim semper pulvinar orci ac lorem egestas tincidunt. Fusce nam eget sit ac feugiat est tristique velit. At ultrices mauris massa viverra a. Nulla orci id velit tincidunt felis leo. Orci imperdiet quam porttitor ut velit adipiscing rhoncus tortor. Posuere turpis facilisi imperdiet suscipit mollis. Est elementum eget blandit neque nisi aliquam velit sapien. Amet quam sollicitudin tellus lobortis posuere morbi sagittis nibh. Nulla eu eget varius vitae et ac quis sit sed. Viverra vel lobortis nunc in iaculis vitae sed consectetur. Arcu nunc ut hac dolor nisi cursus. Platea nisl enim adipiscing laoreet a.",
  vaccination:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus auctor velit faucibus consectetur. Aliquam ac est a sem mattis interdum non nec arcu. Sed tristique nec lacus bibendum condimentum. In hac habitasse platea dictumst. ",
  visa: "Lorem ipsum dolor sit amet consectetur. Eget sed massa consequat pulvinar dis enim sodales cursus. Nulla id purus in quam purus a. Nisi enim semper pulvinar orci ac lorem egestas tincidunt. Fusce nam eget sit ac feugiat est tristique velit. At ultrices mauris massa viverra a.",
};

// Sample data to show cancellation policy taken directly from Figma design
export const cancellationPolicy =
  "Lorem ipsum dolor sit amet consectetur. Eget sed massa consequat pulvinar dis enim sodales cursus. Nulla id purus in quam purus a. Nisi enim semper pulvinar orci ac lorem egestas tincidunt. Fusce nam eget sit ac feugiat est tristique velit. At ultrices mauris massa viverra a. Nulla orci id velit tincidunt felis leo. Orci imperdiet quam porttitor ut velit adipiscing rhoncus tortor. Posuere turpis facilisi imperdiet suscipit mollis. Est elementum eget blandit neque nisi aliquam velit sapien. Amet quam sollicitudin tellus lobortis posuere morbi sagittis nibh. Nulla eu eget varius vitae et ac quis sit sed. Viverra vel lobortis nunc in iaculis vitae sed consectetur. Arcu nunc ut hac dolor nisi cursus. Platea nisl enim adipiscing laoreet a.";
