import { MeTimeAwayLogo, MeTimeAwayLogoMobile, MobileNavSVG } from "../SVG/svg";

export default function Header(props) {
  return (
    <div className="z-50 absolute top-[24px] w-full flex lg:flex-col items-center justify-center gap-[33px]">
      <div className="hidden lg:flex">
        <MeTimeAwayLogo />
      </div>
      <div className="lg:hidden">
        <MeTimeAwayLogoMobile />
      </div>
      <div className="absolute top-[16px] right-[16px] lg:hidden">
        <MobileNavSVG />
      </div>
      {!props.hideLinks && (
        <div className="hidden lg:flex items-center justify-center gap-[80px] tracking-[.2em]">
          <a
            href="https://www.metimeaway.com/retreats/"
            className="font-medium text-white text-[12px]"
          >
            WELLNESS RETREATS
          </a>
          <a
            href="https://www.metimeaway.com/europe-retreats/"
            className="font-medium text-white text-[12px]"
          >
            DESTINATIONS
          </a>
          <a
            href="https://www.metimeaway.com/register/"
            className="font-medium text-white text-[12px]"
          >
            ADD A RETREAT
          </a>
          <a
            href="https://www.metimeaway.com/magazine/"
            className="font-medium text-white text-[12px]"
          >
            MAGAZINE
          </a>
        </div>
      )}
    </div>
  );
}
