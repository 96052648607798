import { Option, Select } from "@mui/joy";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Dinero from "dinero.js";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ListingContext } from "../../../../../contexts/listing";
import { useGetMediaQuery } from "../../../../../services/rtk/osare";
import Divider from "../../UI/Divider/Divider";
import GalleryCover from "../../UI/GalleryCover/GalleryCover";
import SectionTitle from "../../UI/SectionTitle/SectionTitle";
import {
  FreeParkingSVG,
  HeatingSVG,
  LibrarySVG,
  YogaShalaSVG,
} from "../../UI/SVG/svg";

// Accomodation section component
export default function Accomodation({ content }) {
  const { listingData } = useContext(ListingContext);
  const accommodationDescription = useEditor({
    extensions: [StarterKit],
    content: listingData.accommodationData[0].description,
  });
  const [searchParams] = useSearchParams();

  const { data: accommodationGalleryData } = useGetMediaQuery(
    {
      collection: "accommodation",
      collectionId: listingData.accommodationData[0]._id,
    },
    {
      skip: !listingData.accommodationData[0]._id,
    }
  );

  useEffect(() => {
    console.log("searchParams changed");
    console.log("availability is now:", searchParams.get("availabilityId"));
  }, [searchParams]);

  return (
    <>
      <section className="w-full flex flex-col py-[16px] lg:py-[48px] gap-[32px] text-[16px]">
        <SectionTitle
          title={`Accommodation: ${listingData.accommodationData[0].name}`}
        />

        <div className="h-fit flex flex-col xl:flex-row justify-between gap-[32px] pb-[32px]">
          <div className="w-full xl:w-[584px] flex flex-col gap-[40px]">
            <div className="w-full h-fit relative xl:hidden">
              {accommodationGalleryData &&
              accommodationGalleryData.results.length > 0 &&
              accommodationGalleryData.results[0].signedUrl ? (
                <GalleryCover
                  image={accommodationGalleryData.results[0].signedUrl}
                  galleryData={accommodationGalleryData.results}
                />
              ) : null}
            </div>

            <div className="flex flex-col gap-[16px] border-l border-background2 pl-[16px]">
              <div className="flex flex-col">
                <p className="font-bold">Check-in Time:</p>
                <p>{listingData.eventData[0].checkInTime}</p>
              </div>
              <div className="flex flex-col">
                <p className="font-bold">Check-out Time:</p>
                <p>{listingData.eventData[0].checkOutTime}</p>
              </div>
            </div>
            <div
              className="w-full space-y-4"
              dangerouslySetInnerHTML={{
                __html: accommodationDescription.getHTML(),
              }}
            ></div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Basics</p>
              {/* Using sample facilities mulitple times to emulate real data */}
              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faBasics.map((item) => (
                  <SingleFacility text={item} />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Food &amp; Drink</p>

              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faFoodDrink.map((item) => (
                  <SingleFacility text={item} />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Indoor Facilities</p>

              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faIndoor.map((item) => (
                  <SingleFacility text={item} />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Outdoor Facilities</p>

              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faOutdoor.map((item) => (
                  <SingleFacility text={item} />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Rantals</p>

              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faRentals.map((item) => (
                  <SingleFacility text={item} />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Services</p>

              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faServices.map((item) => (
                  <SingleFacility text={item} />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Spa &amp; Beauty</p>

              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faSpaBeauty.map((item) => (
                  <SingleFacility text={item} />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-[16px]">
              <p className="font-bold">Sustainability</p>

              <div className="grid grid-cols-2 gap-4">
                {listingData.accommodationData[0].faSustainability.map(
                  (item) => (
                    <SingleFacility text={item} />
                  )
                )}
              </div>
            </div>
          </div>
          {/* right hand image placement */}
          <div className="w-[563px] h-fit hidden xl:flex">
            {accommodationGalleryData &&
            accommodationGalleryData.results.length > 0 &&
            accommodationGalleryData.results[0].signedUrl ? (
              <GalleryCover
                image={accommodationGalleryData.results[0].signedUrl}
                galleryData={accommodationGalleryData.results}
              />
            ) : null}
          </div>
        </div>
        <div className="flex flex-col gap-[32px]">
          <button
            id="accommodationOptions"
            name="accommodationOptions"
            className="sr-only"
            aria-label="Accommodation Options"
          >
            Accommodation Options
          </button>
          <p className="font-inriaSerif italic text-primary text-[24px]">
            Availability
          </p>
          {searchParams.get("availabilityId") ? (
            listingData.availabilityData
              .find((a) => a._id === searchParams.get("availabilityId"))
              ?.inventory.map((i) => {
                // Map room to intentory ID
                const room = listingData.roomData.find(
                  (r) => r._id === i.associatedRoomId
                );
                return <RoomCard key={room.name} room={room} inventory={i} />;
              })
          ) : (
            <p className="">Please select a date first to view availability.</p>
          )}
        </div>
      </section>
      <Divider />
    </>
  );
}

// Room card component displaying the info of avaliable rooms
const RoomCard = ({ room, inventory }) => {
  console.log("room data", room);
  const [selectedOccupancy, setSelectedOccupancy] = useState(0);
  const { listingData } = useContext(ListingContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const roomDescription = useEditor({
    extensions: [StarterKit],
    content: room.description,
  });

  const price = Dinero({
    amount: inventory.price * 100,
    currency: listingData.merchantAccount.defaultCurrency.toUpperCase(),
  });

  const { data } = useGetMediaQuery(
    {
      collection: "rooms",
      collectionId: room._id,
    },
    {
      skip: !room._id,
    }
  );

  console.log("image data: ", data);

  return (
    <div className="w-full bg-white rounded-md py-[24px] pr-[24px] pl-[16px] xl:pl-[40px] flex flex-col">
      <div className="w-full flex flex-col xl:flex-row justify-between gap-[24px] mb-[32px] lg:mb-0">
        <div className="w-full h-fit relative xl:hidden">
          {data && data.results.length > 0 && data.results[0].signedUrl ? (
            <GalleryCover
              image={data.results[0].signedUrl}
              galleryData={data.results}
            />
          ) : null}
        </div>

        <div className="w-full xl:w-[580px] flex flex-col gap-[12px]">
          <div className="flex flex-col gap-[8px] xl:py-[0px] pr-[48px]">
            <p className="font-inriaSerif italic text-primary text-[24px]">
              {room.name}
            </p>
            <div className="flex items-center gap-[8px] mb-[12px]">
              <span className="text-[14px]">from</span>
              <span className="font-inriaSerif italic text-[20px]">
                {price.toFormat("$0,0")}
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-[16px] border-l border-background2 pl-[16px] mb-4">
            {room.sleepingArrangements.map((sa) => (
              <div className="flex flex-col">
                {/* <p className="font-bold">{sa[0].name[0].text}</p> */}
                <p className="font-bold">{sa.name[0].text}</p>
                <p>Sleeps {sa.occupant_count}</p>
              </div>
            ))}
          </div>

          <div
            className="whitespace-pre-line space-y-4"
            dangerouslySetInnerHTML={{ __html: roomDescription.getHTML() }}
          ></div>
        </div>
        <div className="w-[580px] h-fit relative hidden xl:flex">
          {data && data.results.length > 0 && data.results[0].signedUrl ? (
            <GalleryCover
              image={data.results[0].signedUrl}
              galleryData={data.results}
            />
          ) : null}
        </div>
      </div>
      {room.amenities.basics.length > 0 && (
        <div className="flex flex-col py-[12px] xl:py-[32px] xl:px-[24px] gap-[24px]">
          <p className="font-bold">Basics</p>
          {/* Using sample facilities mulitple times to emulate real data */}
          <div className="grid grid-cols-2 xl:grid-cols-4 gap-[12px] xl:gap-[24px]">
            {room.amenities.basics.map((item) => (
              <SingleFacility text={item} />
            ))}
          </div>
        </div>
      )}
      {room.amenities.bathroom.length > 0 && (
        <div className="flex flex-col py-[12px] xl:py-[32px] xl:px-[24px] gap-[24px]">
          <p className="font-bold">Bathroom</p>
          {/* Using sample facilities mulitple times to emulate real data */}
          <div className="grid grid-cols-2 xl:grid-cols-4 gap-[12px] xl:gap-[24px]">
            {room.amenities.bathroom.map((item) => (
              <SingleFacility text={item} />
            ))}
          </div>
        </div>
      )}
      {room.amenities.bathroom.length > 0 && (
        <div className="flex flex-col py-[12px] xl:py-[32px] xl:px-[24px] gap-[24px]">
          <p className="font-bold">Extras</p>
          {/* Using sample facilities mulitple times to emulate real data */}
          <div className="grid grid-cols-2 xl:grid-cols-4 gap-[12px] xl:gap-[24px]">
            {room.amenities.extras.map((item) => (
              <SingleFacility text={item} />
            ))}
          </div>
        </div>
      )}
      <div className="w-96 place-self-center">
        <p className="text-center p-4">How many do you want to book?</p>
        <Select
          fullWidth
          value={selectedOccupancy}
          onChange={(e, v) => {
            console.log(v);
            setSelectedOccupancy(v);
          }}
        >
          <Option selected disabled value={0}>
            Please select a quantity
          </Option>
          {Array.from(
            Array(inventory.quantity - inventory.quantityPurchased)
          ).map((e, i) => (
            <Option value={i + 1}>{i + 1}</Option>
          ))}
        </Select>
        <div className="p-2" />
        <button
          type="submit"
          className="min-h-[48px] h-fit w-full bg-primaryOutline text-white hover:bg-sky-700 rounded-md py-[6px] px-[24px] text-center"
          onClick={() => {
            if (selectedOccupancy <= 0) {
              alert("Please select a quantity first!");
              return;
            }

            navigate(
              `/book?listingId=${searchParams.get(
                "listingId"
              )}&availabilityId=${searchParams.get(
                "availabilityId"
              )}&inventoryId=${inventory._id}&roomQuantity=${selectedOccupancy}`
            );
          }}
        >
          Continue{selectedOccupancy <= 1 ? "" : " with " + selectedOccupancy}
        </button>
      </div>
    </div>
  );
};

// 4 Sample Facilities with svg of each logo provided from Figma file
const SingleFacility = ({ text }) => {
  return <div className="flex gap-[8px] xl:gap-[20px]">{text}</div>;
};

// eslint-disable-next-line no-unused-vars
const Facilities = () => {
  return (
    <>
      <div className="flex gap-[8px] xl:gap-[20px]">
        <FreeParkingSVG />
        <p>Free Parking</p>
      </div>
      <div className="flex gap-[8px] xl:gap-[20px]">
        <HeatingSVG />
        <p>Heating</p>
      </div>
      <div className="flex gap-[8px] xl:gap-[20px]">
        <LibrarySVG />
        <p>Library</p>
      </div>
      <div className="flex gap-[8px] xl:gap-[20px]">
        <YogaShalaSVG />
        <p>Yoga Shala/ Deck</p>
      </div>
    </>
  );
};
