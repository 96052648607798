import { Avatar, Card, Grid, Typography } from "@mui/joy";
import { useGetMediaQuery } from "../../services/rtk/osare";

export default function StaffList(props) {
  return (
    <Grid container spacing={2}>
      {props.staffData.map((staff, index) => (
        <StaffRow staff={staff} index={index} />
      ))}
    </Grid>
  );
}

const StaffRow = ({ staff, index }) => {
  const { data, isLoading, isSuccess } = useGetMediaQuery({
    collection: "staff",
    collectionId: staff._id,
  });

  return (
    <Grid item key={index} xs={12}>
      <Card>
        <Grid container spacing={2} display={"flex"}>
          <Grid>
            <Avatar
              size="lg"
              src={isSuccess ? data.results[0].signedUrl : ""}
              alt={staff.firstName}
            />
          </Grid>
          <Grid>
            <Typography gutterBottom level="title-sm">
              {staff.firstName} {staff.lastName}
            </Typography>
            <Typography level="body-xs">{staff.role}</Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
