// @ts-nocheck
import { MapTwoTone } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CircularProgress, Divider, Link } from "@mui/joy";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResponsiveAppBar from "../../../components/Appbar/Appbar";
import BookingAccommodation from "../../../components/BookingAccommodation/BookingAccommodation";
import BookingSummary from "../../../components/BookingSummary/BookingSummary";
import ContactList from "../../../components/ContactList/ContactList";
import FinalOptionsList from "../../../components/FinalOptionsList/FinalOptionsList";
import PaymentNotStarted from "../../../components/PaymentNotStarted/PaymentNotStarted";
import PaymentNotSuccessful from "../../../components/PaymentNotSuccessful/PaymentNotSuccessful";
import PaymentsSchedule from "../../../components/PaymentsSchedule/PaymentsSchedule";
import StaffList from "../../../components/StaffList/StaffList";
import WrapperContainer from "../../../components/WrapperContainer/WrapperContainer";
import { useGetStorefrontUserOrderByIdQuery } from "../../../services/rtk/osare";

export default function MyBookingViewer() {
  const { state } = useLocation();
  const auth = getAuth();
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: orderData,
    isLoading,
    isFetching,
    isSuccess,
    error,
    refetch,
  } = useGetStorefrontUserOrderByIdQuery({ id: params.orderId });

  // const {
  //   data: listingsData,
  //   isLoading: isLoadingListings,
  //   isFetching: isFetchingListings,
  //   refetch: listingsRefetch,
  // } = useGetStorefrontUserOrderListingsQuery();

  // useEffect(() => {
  //   console.log("orderData", orderData);
  //   if (!orderData) {
  //     refetch();
  //   }
  // }, [orderData]);

  /**
   * Initial load
   */
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // When a user exists, perform the call
      if (user) {
        refetch();
      }
    });
  }, [auth, refetch]);

  // console.log(state);
  // console.log(params);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  const Loading = () => {
    return (
      <Box textAlign={"center"} marginTop={2}>
        <CircularProgress />
      </Box>
    );
  };

  const FullOrder = ({ orderData }) => {
    return (
      <Card>
        <BookingSummary orderData={orderData.results[0]} />
        <a name="contact" />
        <Divider>Contact</Divider>
        <ContactList
          merchantData={orderData.results[0].listingData.merchantAccount}
        />
        <Divider>Location</Divider>
        <Link
          target="_blank"
          href={`https://maps.google.com/?q=${orderData.results[0].accommodationData.location.formattedAddress}`}
          startDecorator={<MapTwoTone />}
        >
          {orderData.results[0].accommodationData.location.formattedAddress}
        </Link>
        <Link
          level={"body-xs"}
          mt={-1}
          ml={4}
          target="_blank"
          href={`https://maps.google.com/?q=${orderData.results[0].accommodationData.location.formattedAddress}`}
        >
          Click to open in Google Maps
        </Link>
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <Map
            style={{ width: "100%", height: "300px" }}
            mapId={"map"}
            defaultCenter={{
              lat: orderData.results[0].accommodationData.location.geometry.lat,
              lng: orderData.results[0].accommodationData.location.geometry.lng,
            }}
            defaultZoom={15}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
          >
            <AdvancedMarker
              position={{
                lat: orderData.results[0].accommodationData.location.geometry
                  .lat,
                lng: orderData.results[0].accommodationData.location.geometry
                  .lng,
              }}
              title={"AdvancedMarker that opens an Infowindow when clicked."}
            />
          </Map>
        </APIProvider>
        <Divider>Accommodation</Divider>
        <BookingAccommodation {...orderData.results[0]} />
        <Divider>Instructors</Divider>
        <StaffList staffData={orderData.results[0].listingData.staffData} />
        <PaymentsSchedule {...orderData.results[0]} orderRefetch={refetch} />
        <Divider>Options &amp; Information</Divider>
        <FinalOptionsList order={orderData.results[0]} />
      </Card>
    );
  };

  return (
    <WrapperContainer>
      <ResponsiveAppBar />
      {!orderData ? (
        <Loading />
      ) : (
        <>
          <Box marginBottom={2}>
            <Button
              variant="plain"
              onClick={() => {
                navigate("/my/bookings");
              }}
              startDecorator={<ArrowBackIcon />}
            >
              Back to Bookings
            </Button>
          </Box>

          {orderData?.results[0]?.scheduledPayments.length === 0 ? (
            <>
              <PaymentNotStarted />
              <FinalOptionsList
                hideOrganiserContact
                order={orderData.results[0]}
              />
            </>
          ) : orderData?.results[0]?.scheduledPayments.find(
              (s) => s.purpose === "platformAndDepositFee"
            )?.status === "succeeded" ? (
            <FullOrder orderData={orderData} />
          ) : (
            <PaymentNotSuccessful orderData={orderData.results[0]} />
          )}
        </>
      )}
    </WrapperContainer>
  );
}
