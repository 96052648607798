import { Email, Phone } from "@mui/icons-material";
import { Card, Link } from "@mui/joy";

export default function ContactList({ merchantData }) {
  return (
    <Card>
      <Link
        href={`mailto:${merchantData.supportEmail}`}
        startDecorator={<Email />}
      >
        Email: {merchantData.supportEmail}
      </Link>
      <Link
        href={`tel:${merchantData.supportPhone}`}
        startDecorator={<Phone />}
      >
        Phone: {merchantData.supportPhone}
      </Link>
    </Card>
  );
}
