import { Launch } from "@mui/icons-material";
import { Chip, Link, Typography } from "@mui/joy";
import { DateTime } from "luxon";
import BookingDateRange from "../BookingDateRange/BookingDateRange";

export default function BookingSummary(props) {
  console.log("BookingSummary", props);
  return (
    <>
      {props.orderData.isCancelled ? (
        <Chip color="danger">
          {props.orderData.isDenied ? "Denied" : "Cancelled"}{" "}
          {props.orderData.isCancelledByMerchant ? "by merchant" : "by you"}-
          initially booked on{" "}
          {DateTime.fromMillis(props.orderData.createdAt).toLocaleString(
            DateTime.DATETIME_MED_WITH_WEEKDAY
          )}
        </Chip>
      ) : (
        <Chip
          color={props.orderData.isAwaitingApproval ? "warning" : "primary"}
        >
          {props.orderData.isAwaitingApproval
            ? "Awaiting Approval"
            : "Confirmed"}{" "}
          - booked on{" "}
          {DateTime.fromMillis(props.orderData.createdAt).toLocaleString(
            DateTime.DATETIME_MED_WITH_WEEKDAY
          )}
        </Chip>
      )}
      <Typography marginBottom={0} level="title-lg">
        <Link
          href={`/listing?listingId=${props.orderData?.listingData?._id}`}
          target="_blank"
          startDecorator={<Launch />}
        >
          {props.orderData.listingData
            ? props.orderData.listingData.name
            : "..."}
        </Link>
      </Typography>

      <Typography gutterBottom level="title-sm">
        {props.orderData.listingData
          ? props.orderData.listingData.tagline
          : "..."}
      </Typography>

      {props.orderData.availabilityData && (
        <BookingDateRange orderData={props.orderData} />
      )}
      {props.createdAt ? (
        <Typography level="title-sm">
          Booked on:{" "}
          {DateTime.fromMillis(props.createdAt).toLocaleString(
            DateTime.DATE_MED_WITH_WEEKDAY
          )}
        </Typography>
      ) : null}
    </>
  );
}
