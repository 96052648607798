import { LinearProgress } from "@mui/joy";
import React from "react";

export default function LoadingFullHeight() {
  return (
    <div className="bg-neutral h-screen flex items-center justify-center">
      <div className="w-96">
        <p className="text-center mb-4">Just a moment...</p>
        <LinearProgress color="neutral" />
      </div>
    </div>
  );
}
