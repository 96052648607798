import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Divider,
  Dropdown,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { MeTimeAwayLogoBlack } from "../../themes/metimeaway/components/UI/SVG/MeTimeAwayLogoBlack";

function ResponsiveAppBar(props) {
  const navigate = useNavigate();
  const auth = getAuth();

  return (
    <Grid container>
      <Grid xs={10} mb={2} ml={-1.5}>
        <Box ml={1.5} mt={1} width={300}>
          <MeTimeAwayLogoBlack />
        </Box>
      </Grid>
      <Grid xs={2} textAlign={"right"} mt={2}>
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: "outlined", color: "neutral" } }}
          >
            <MoreVert />
          </MenuButton>
          <Menu placement="bottom-end">
            {auth.currentUser && (
              <>
                <MenuItem>
                  <b>{auth.currentUser.displayName}</b>
                </MenuItem>
                <Divider />
              </>
            )}
            <MenuItem
              onClick={() => {
                navigate("/my/bookings");
              }}
            >
              Bookings
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/my/payment-methods");
              }}
            >
              Payment Methods
            </MenuItem>
            <MenuItem
              onClick={() => {
                auth.signOut();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Dropdown>
      </Grid>
    </Grid>
  );
}
export default ResponsiveAppBar;
