export default function Footer() {
  return (
    <div className="w-full h-fit bg-background  flex flex-col lg:flex-row justify-start items-start px-[24px] lg:px-[120px] py-[48px] lg:py-[64px] text-text text-[16px] gap-[24px] lg:gap-0 font-light tracking-wider">
      <div className="w-full flex flex-col gap-[16px]">
        <p className="font-semibold text-[16px]">About us</p>
        <div className="flex flex-col gap-[12px] pl-[12px]">
          <a href="https://www.metimeaway.com/who-we-are/">
            who we are & contact
          </a>
          <a href="https://www.metimeaway.com/about-us/why-figs/">why figs?</a>
          <a href="https://www.metimeaway.com/terms-of-use/">terms of use</a>
          <a href="https://www.metimeaway.com/privacy-and-cookies-policy/">
            privacy & cookie policy
          </a>
          <a href="https://www.metimeaway.com/register/">
            become a partner / add retreat
          </a>
        </div>
      </div>
      <div className="w-full flex flex-col gap-[16px]">
        <p className="font-semibold text-[16px]">Proudly based in London, UK</p>
        <p className="text-textTertiary font-normal text-[12px] ">
          © Osare.io Ltd, Copyright {new Date().getFullYear()}
        </p>
        <div className="flex flex-col gap-[12px] pl-[12px]">
          <a href="https://www.metimeaway.com/sitemap/">sitemap</a>
          <a href="https://www.metimeaway.com/credits/">credits</a>
        </div>
      </div>
    </div>
  );
}
