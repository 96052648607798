import { Box } from "@mui/joy";
import React from "react";
import { MeTimeAwayLogoBlack } from "../../themes/metimeaway/components/UI/SVG/MeTimeAwayLogoBlack";

export default function Home() {
  return (
    <div className="bg-neutral h-screen flex items-center justify-center">
      <div className="w-96">
        <div className="flex justify-center">
          <Box width={400}>
            <MeTimeAwayLogoBlack />
          </Box>
        </div>
        <a href="/login" className="block text-center mt-4">
          Login
        </a>
      </div>
    </div>
  );
}
