import { ReplayOutlined, Warning } from "@mui/icons-material";
import {
  Alert,
  AspectRatio,
  Box,
  Button,
  Card,
  LinearProgress,
  Link,
  Skeleton,
  Typography,
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../../components/Appbar/Appbar";
import BookingSummary from "../../../components/BookingSummary/BookingSummary";
import WrapperContainer from "../../../components/WrapperContainer/WrapperContainer";
import {
  useLazyGetStorefrontUserOrderListingsQuery,
  useLazyGetStorefrontUserOrdersQuery,
} from "../../../services/rtk/osare";

export default function MyBookings() {
  const [
    findUserOrders,
    {
      data: userOrdersData,
      isFetching: isFetchingUserOrders,
      isLoading: isLoadingUserOrders,
      error: errorUserOrders,
    },
  ] = useLazyGetStorefrontUserOrdersQuery();
  const [
    findUserOrderListings,
    {
      data: userOrderListingsData,
      isFetching: isFetchingUserOrderListings,
      isLoading: isLoadingUserOrderListings,
      error: errorUserOrderlistings,
    },
  ] = useLazyGetStorefrontUserOrderListingsQuery();
  const navigate = useNavigate();
  const auth = getAuth();

  /**
   * Initial load
   */
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // When a user exists, perform the call
      if (user) {
        findUserOrderListings({});
        findUserOrders({});
      }
    });
  }, [auth, findUserOrderListings, findUserOrders]);

  const BookingItemComponent = (props) => {
    console.log("BookingItemComponent", props);
    console.log("BookingItemComponent listingsData", userOrderListingsData);

    if (isFetchingUserOrders || isFetchingUserOrderListings) {
      return (
        <Card color="primary" sx={{ mb: 2 }}>
          <AspectRatio ratio="21/3">
            <Skeleton animation="wave" variant="overlay" />
          </AspectRatio>
        </Card>
      );
    }

    return (
      <Box marginBottom={2}>
        <Card
          color={
            props.isCancelled
              ? "danger"
              : props.isAwaitingApproval
              ? "warning"
              : "primary"
          }
        >
          <BookingSummary
            orderData={{
              ...props.orderData,
              listingData: userOrderListingsData.results.find(
                (l) => l._id === props.orderData.listingId
              ),
            }}
          />

          <Button
            onClick={() => navigate(`/my/bookings/view/${props.orderData._id}`)}
          >
            View Booking
          </Button>
        </Card>
      </Box>
    );
  };

  return (
    <>
      <WrapperContainer>
        <ResponsiveAppBar />
        <Typography
          level="h4"
          marginBottom={3}
          endDecorator={
            <Link
              startDecorator={<ReplayOutlined />}
              onClick={() => {
                findUserOrders({});
                findUserOrderListings({});
              }}
            ></Link>
          }
        >
          Bookings{" "}
        </Typography>
        {(isLoadingUserOrders ||
          isLoadingUserOrderListings ||
          isFetchingUserOrders ||
          isFetchingUserOrderListings) && (
          <Box marginY={2}>
            <LinearProgress />
          </Box>
        )}

        {errorUserOrders && (
          <Alert color="danger" startDecorator={<Warning />}>
            <Typography>
              Sorry, there was an error whilst trying to load your bookings.{" "}
              <Link
                onClick={() => {
                  findUserOrders({});
                  findUserOrderListings({});
                }}
              >
                Try again?
              </Link>
            </Typography>
          </Alert>
        )}

        {(!isLoadingUserOrders || !isLoadingUserOrderListings) &&
          userOrdersData?.results.map((order) => (
            <BookingItemComponent orderData={order} />
          ))}
      </WrapperContainer>
    </>
  );
}
