//import sampleData from "./sample-listing-data";
import { Check, HourglassBottom } from "@mui/icons-material";
import { generateHTML } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Dinero from "dinero.js";
import { getAuth } from "firebase/auth";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DocumentViewerModal from "../../components/DocumentViewerModal/DocumentViewerModal";
import { ListingContext } from "../../contexts/listing";
import {
  useCreateCheckoutSessionMutation,
  useCreateCustomerMutation,
  useLazyGetStorefrontListingQuery,
} from "../../services/rtk/osare";
import { calculateDaysToPackageCharge } from "../../utilities/utils";
import Divider from "./components/UI/Divider/Divider";
import Header from "./components/UI/Header/Header";
import HeroSection from "./components/UI/HeroSection/HeroSection";
import LoadingFullHeight from "./components/UI/Loading/Loading";
import SectionTitle from "./components/UI/SectionTitle/SectionTitle";

export default function ThemedBook() {
  let [searchParams] = useSearchParams();
  const [createCheckoutSession, createCheckoutSessionResult] =
    useCreateCheckoutSessionMutation();
  const auth = getAuth();
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [createCustomer, createCustomerResult] = useCreateCustomerMutation();
  const [customerId, setCustomerId] = useState(false);
  const navigate = useNavigate();
  const [
    getListing,
    { data: fetchedListingData, isSuccess: isListingSuccess },
  ] = useLazyGetStorefrontListingQuery();

  const [listingData, setListingData] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [inventoryData, setInventoryData] = useState(null);
  const [accommodationData, setAccommodationData] = useState(null);
  const [roomData, setRoomData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [isLegalAccepted, setIsLegalAccepted] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [documentViewerOpen, setDocumentViewerOpen] = useState(false);

  useEffect(() => {
    if (
      !searchParams.get("roomQuantity") ||
      searchParams.get("roomQuantity") === "0" ||
      !searchParams.get("availabilityId") ||
      !searchParams.get("inventoryId")
    ) {
      navigate(-1);
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    auth.onAuthStateChanged((authResult) => {
      if (!authResult) {
        navigate(
          `/login?returnTo=${encodeURIComponent(
            `/listing?listingId=${searchParams.get("listingId")}`
          )}`
        );
      } else {
        setLoggedInUser(authResult);
        getListing(searchParams.get("listingId"));
      }
    });
  }, [auth, getListing, navigate, searchParams, setLoggedInUser]);

  useEffect(() => {
    if (fetchedListingData && fetchedListingData.results) {
      document.title = `${fetchedListingData.results[0].name} | ${fetchedListingData.results[0].config.name}`;
      createCustomer({
        merchantStripeAccountId:
          fetchedListingData.results[0].merchantAccount.stripeAccountId,
        merchantFirebaseUserId: fetchedListingData.results[0].firebaseUserId,
      });

      /**
       * Get top level listing data
       */
      setListingData(fetchedListingData.results[0]);

      // const merchantTerms = useEditor({
      //   extensions: [StarterKit],
      //   content: listingData.accommodationData[0].description,
      // });

      /**
       * Get inventory data
       */
      const inventoryData = fetchedListingData.results[0].availabilityData
        .find((a) => a._id === searchParams.get("availabilityId"))
        .inventory.find((i) => i._id === searchParams.get("inventoryId"));

      console.log("inventoryData", inventoryData);
      setInventoryData(inventoryData);

      /**
       * Get availability data
       */
      const availabilityData =
        fetchedListingData.results[0].availabilityData.find(
          (a) => a._id === inventoryData.associatedAvailabilityId
        );
      console.log("availabilityData", availabilityData);
      setAvailabilityData(availabilityData);

      /**
       * Get accommodation data
       */
      const accommodationData =
        fetchedListingData.results[0].accommodationData[0];
      console.log("accommodationData", accommodationData);
      setAccommodationData(accommodationData);

      /**
       * Get room data
       */
      const roomData = fetchedListingData.results[0].roomData.find(
        (r) => r._id === inventoryData.associatedRoomId
      );
      console.log("roomData", roomData);
      setRoomData(roomData);

      /**
       * Get event data
       */
      const eventData = fetchedListingData.results[0].eventData.find(
        (e) => e._id === inventoryData.associatedEventId
      );
      console.log("eventData", eventData);
      setEventData(eventData);
    }
  }, [createCustomer, fetchedListingData, searchParams]);

  useEffect(() => {
    if (
      createCustomerResult.status === "fulfilled" &&
      createCustomerResult.data
    ) {
      setCustomerId(createCustomerResult.data?.results[0].stripeCustomerId);
    }
  }, [createCustomerResult]);

  useEffect(() => {
    if (createCheckoutSessionResult.isSuccess) {
      console.log("createCheckoutSessionResult:", createCheckoutSessionResult);
      window.location.href =
        createCheckoutSessionResult.data.results[0].redirectUrl;
    }

    if (createCheckoutSessionResult.isError) {
      alert(
        "Sorry, we couldn't create a checkout session for you. We will reload the page for you and please try again."
      );
    }
  }, [createCheckoutSessionResult]);

  const openDocumentViewer = (documentId) => {
    setDocumentId(documentId);
    setDocumentViewerOpen(true);
  };

  return (
    <div>
      {createCustomerResult.isSuccess && isListingSuccess ? (
        <div>
          <ListingContext.Provider
            value={{ listingData: fetchedListingData.results[0] }}
          >
            <Header hideLinks />
            <HeroSection />
            <div className="font-mulish bg-background text-text h-full flex flex-col items-center text-[14px] lg:text-[16px] px-[16px] lg:px-[120px]">
              <div className="w-full max-w-[1440px]">
                <>
                  <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
                    <SectionTitle title="Selected Dates" />

                    <div className="whitespace-pre-line space-y-4">
                      <p className="text-xl">From: </p>
                      <p className="text-2xl" style={{ marginTop: 0 }}>
                        {DateTime.fromMillis(
                          availabilityData.fromTimestamp
                        ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                      </p>
                      <p className="text-xl">To: </p>
                      <p className="text-2xl" style={{ marginTop: 0 }}>
                        {DateTime.fromMillis(
                          availabilityData.toTimestamp
                        ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                      </p>
                    </div>
                  </section>
                  <Divider />
                </>

                <>
                  <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
                    <SectionTitle title="Selected Accommodation" />

                    <div className="">
                      <p className="text-xl mb-2">{accommodationData?.name}</p>
                      <p className="text-2xl">
                        {searchParams.get("roomQuantity")}x {roomData?.name}
                      </p>
                    </div>
                  </section>
                  <Divider />
                </>

                {listingData.askForInsurance && (
                  <>
                    <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
                      <SectionTitle title="Insurance" />
                      <div className="flex text-[18px] lg:text-[16px]">
                        <div className="whitespace-pre-line space-y-4">
                          <p className="text-xl">
                            {listingData.merchantAccount.legalEntityName} has
                            asked that you hold the relevant insurance for this
                            booking. Please ensure you have the correct
                            insurance in place before continuing.
                          </p>
                        </div>
                      </div>
                    </section>
                    <Divider />
                  </>
                )}

                <>
                  <div id="merchant-terms" />
                  <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
                    <SectionTitle title="Terms &amp; Conditions" />
                    <div className="flex text-[18px] lg:text-[16px]">
                      <div className="whitespace-pre-line space-y-4">
                        <p className="text-xl mb-8">
                          {listingData.merchantAccount.legalEntityName} has
                          stated the following Terms &amp; Conditions for this
                          booking:
                        </p>
                        <div
                          className="whitespace-pre-line space-y-4 h-36 overflow-scroll"
                          dangerouslySetInnerHTML={{
                            __html: generateHTML(
                              listingData.termsAndConditions,
                              [StarterKit]
                            ),
                          }}
                        ></div>
                      </div>
                    </div>
                  </section>
                  <Divider />
                </>

                <>
                  <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
                    <SectionTitle title="Payment Policy" />
                    <div className="">
                      <TotalPriceComponent
                        roomQuantity={searchParams.get("roomQuantity")}
                        inventoryItem={inventoryData}
                        listing={listingData}
                        availabilityData={availabilityData}
                      />
                      {/* <div className="whitespace-pre-line space-y-4">
                        Hello how are you
                      </div> */}
                    </div>
                  </section>
                  <Divider />
                </>

                <>
                  <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
                    <SectionTitle title="Legal" />
                    <div className="">
                      <p className="text-xl mb-4">
                        By continuing, i agree that i have read{" "}
                        <a
                          style={{
                            background: "none",
                            border: "none",
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                            padding: 0,
                            font: "inherit",
                          }}
                          href="#merchant-terms"
                        >
                          {listingData.merchantAccount.legalEntityName}'s Terms
                          &amp; Conditions
                        </a>{" "}
                        for this booking, the{" "}
                        <button
                          onClick={() => openDocumentViewer("terms_of_service")}
                          style={{
                            background: "none",
                            border: "none",
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                            padding: 0,
                            font: "inherit",
                          }}
                        >
                          Website Terms of Service
                        </button>{" "}
                        and{" "}
                        <button
                          onClick={() => openDocumentViewer("privacy_policy")}
                          style={{
                            background: "none",
                            border: "none",
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                            padding: 0,
                            font: "inherit",
                          }}
                        >
                          Privacy Policy
                        </button>
                      </p>

                      <div>
                        <label className="text-xl font-bold">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="value"
                            onChange={(e) =>
                              setIsLegalAccepted(e.target.checked)
                            }
                          />
                          {` I understand and accept ${listingData.merchantAccount.legalEntityName}'s Terms & Conditions for this booking, the Website Terms of Service and Privacy Policy`}
                        </label>
                      </div>
                    </div>
                  </section>
                  <Divider />
                </>

                <>
                  <section className="w-full flex flex-col xl:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
                    <SectionTitle title="Reserve" />
                    <div>
                      <div className="w-full pb-8">
                        {listingData.ordersMustBeConfirmed ? (
                          <>
                            <p className="text-xl">
                              <HourglassBottom sx={{ width: 40, height: 40 }} />{" "}
                              {listingData.merchantAccount.legalEntityName} will
                              confirm this booking first before any payments are
                              taken
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="text-xl">
                              <Check sx={{ width: 40, height: 40 }} /> This
                              booking will be instantly confirmed by{" "}
                              {listingData.merchantAccount.legalEntityName}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="w-full mt-3">
                        <button
                          type="submit"
                          disabled={
                            !isLegalAccepted ||
                            createCheckoutSessionResult.isLoading
                          }
                          onClick={() => {
                            createCheckoutSession({
                              availabilityId:
                                searchParams.get("availabilityId"),
                              inventoryId: inventoryData._id,
                              inventoryQuantity:
                                searchParams.get("roomQuantity"),
                              listingId: searchParams.get("listingId"),
                              accommodationId: accommodationData._id,
                              roomId: roomData._id,
                              stripeCustomerId: customerId,
                              merchantStripeAccountId:
                                listingData.merchantAccount.stripeAccountId,
                              policyId: "TBC",
                            });
                          }}
                          className={`min-h-[48px] h-fit w-full ${
                            isLegalAccepted &&
                            !createCheckoutSessionResult.isLoading
                              ? "bg-primaryOutline text-white hover:bg-sky-700"
                              : "bg-slate-200 text-slate-600"
                          } rounded-md py-[6px] px-[24px] text-center`}
                        >
                          {isLegalAccepted
                            ? createCheckoutSessionResult.isLoading
                              ? "Loading..."
                              : "Add Card & Reserve"
                            : "Accept Terms First"}
                        </button>
                      </div>
                    </div>
                  </section>
                  <Divider />
                </>
              </div>
            </div>
            {/* <EmailSubscribe /> */}
            {/* <Footer /> */}
          </ListingContext.Provider>
          <DocumentViewerModal
            documentId={documentId}
            open={documentViewerOpen}
            setOpenFn={setDocumentViewerOpen}
          />
        </div>
      ) : (
        <LoadingFullHeight />
      )}
    </div>
  );
}

const TotalPriceComponent = ({
  roomQuantity,
  inventoryItem,
  listing,
  availabilityData,
}) => {
  const packageFee = roomQuantity * inventoryItem.price;
  const formattedPackageFee = Dinero({
    amount: packageFee * 100,
    currency: listing.merchantAccount.defaultCurrency.toUpperCase(),
  });

  return (
    <>
      <div className="mb-4">
        <p className="text-xl">Total</p>
        <p className="text-2xl">{formattedPackageFee.toFormat("$0,0.00")}</p>
      </div>

      <div>
        <p className="text-xl">Payment Breakdown</p>
      </div>

      <div className="bg-background2 rounded-lg text-white px-3 py-1 my-4">
        <p className="font-bold">
          A {listing.depositPercentage}% deposit has been set by{" "}
          {listing.merchantAccount.legalEntityName}
        </p>
      </div>

      <div className="mb-4">
        <p className="text-xl">
          Due now - {listing.depositPercentage}% deposit
        </p>
        <p className="text-2xl">
          {Dinero({
            amount: Math.ceil(packageFee * listing.depositPercentage),
            currency: listing.merchantAccount.defaultCurrency.toUpperCase(),
          }).toFormat("$0,0.00")}
        </p>
        <p className="text-l font-bold">
          {listing.merchantAccount.legalEntityName}{" "}
          {listing.isDepositRefundable ? "offers" : "does not offer"} a
          refundable deposit
        </p>
      </div>

      <div>
        <p className="text-xl">
          Remaining cost due{" "}
          {calculateDaysToPackageCharge({
            listing,
            availabilityItem: availabilityData,
          }).daysTillCharge <= 0
            ? "now:"
            : listing.chargeRemainingAmountDaysBeforeTravel +
              " days (" +
              calculateDaysToPackageCharge({
                listing,
                availabilityItem: availabilityData,
              }).travelDateWithPolicy.toLocaleString() +
              ") before travel:"}{" "}
        </p>
        <p className="text-2xl">
          {Dinero({
            amount:
              packageFee * 100 -
              Math.ceil(packageFee * listing.depositPercentage),
            currency: listing.merchantAccount.defaultCurrency.toUpperCase(),
          }).toFormat("$0,0.00")}
        </p>
      </div>
    </>
  );
};
