import { useContext } from "react";
import { ListingContext } from "../../../../../contexts/listing";
import { LocationSVG } from "../SVG/svg";

// Expanded Hero Card can be used for Wide Desktop Screens, mtaches figma design for wide desktop
export default function HeroCardExtended({ content }) {
  const { listingData } = useContext(ListingContext);

  return (
    <div className="flex bg-white w-fit h-fit rounded-md px-[16px] py-[16px] lg:px-[32px] lg:py-[24px] gap-[48px]">
      <div className="flex flex-col gap-[16px] w-fit">
        <h1 className="font-inriaSerif italic text-primary w-fit max-w-[470px] text-[24px]">
          {content.title}
        </h1>
        <div className="flex gap-[8px] pb-[8px]">
          <p className="text-primary text-[14px] font-bold">
            By {listingData.merchantAccount.legalEntityName}
          </p>
        </div>
        <div className="flex gap-[8px] pb-[8px]">
          <LocationSVG />
          <p className="text-textSecondary text-[14px]">
            {
              listingData.accommodationData[0].location.addressComponents.find(
                (ac) => ac.types.includes("administrative_area_level_2")
              ).long_name
            }
          </p>
        </div>
        {/* <div className="flex items-center gap-[8px]">
          <div className="w-fit h-fit bg-background2 rounded-full flex gap-[8px] p-[6px] text-white text-[12px]">
            <WhiteStarSVG />
            <span>Best price guarantee</span>
          </div>
          <div className="flex gap-[4px] items-center pl-[8px]">
            <span className="font-semibold text-text text-[16px]">
              {content.rating}
            </span>
            <StarSVG />
          </div>
          <p className="text-textSecondary text-[12px] underline decoration-primary">{`(${content.reviewCount} reviews)`}</p>
        </div> */}
      </div>

      {/* <ExtendedSection
        price={content.price}
        previousPrice={content.previousPrice}
        deposit={content.deposit}
      /> */}
    </div>
  );
}

const ExtendedSection = ({ price, previousPrice, deposit }) => {
  return (
    <div className="w-fit h-fit bg-neutral flex flex-col items-center justify-center gap-[24px] p-[16px] rounded-md">
      <div className="w-full items-end flex flex-col gap-[4px] text-textSecondary">
        <p className="text-[14px] font-light">from</p>
        <div className="flex gap-[12px] items-center">
          <div className="flex gap-[4px] text-[12px] mt-[2px]">
            <p>was</p>
            <p className="line-through">£{previousPrice}</p>
          </div>
          <div className="flex gap-[4px]">
            <p className="font-inriaSerif italic text-primary text-[20px]">
              £{price}
            </p>
            <p className="mt-[2px] font-light">p/person</p>
          </div>
        </div>
        <p className="text-[12px]">{`incl £${deposit} deposit`}</p>
      </div>
      <div className="flex gap-[8px] text-[14px]">
        <button className="h-[40px] w-fit items-center justify-center bg-neutral text-primaryOutline placeholder-primaryOutline border border-primaryOutline rounded-md py-[6px] px-[16px]">
          Contact Host
        </button>
        <button className="h-[40px] w-fit bg-primaryOutline text-neutral rounded-md py-[6px] px-[24px] text-center">
          Book Now
        </button>
      </div>
    </div>
  );
};
