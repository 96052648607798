import { Option, Select } from "@mui/joy";
import Dinero from "dinero.js";
import { DateTime } from "luxon";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ListingContext } from "../../../../../contexts/listing";
import { CalendarSVG } from "../SVG/svg";

// Follows figma design for mobile where book bar appears below Perfect For section and sticks to top of screen when user scrolls down
export default function StickyBookBarMobile() {
  const { listingData } = useContext(ListingContext);
  const price = Dinero({
    amount: listingData.minimumPrice * 100,
    currency: listingData.merchantAccount.defaultCurrency.toUpperCase(),
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const onDateRangeChange = (e, v) => {
    if (v === "0") {
      searchParams.delete("availabilityId");
      setSearchParams(searchParams);

      return;
    }

    searchParams.set("availabilityId", v);
    setSearchParams(searchParams);
    document.getElementById("accommodationOptions").scrollIntoView(true);
  };

  return (
    <div className="backdrop-blur-md bg-neutral/60	w-full sm:h-[111px] flex items-center justify-center p-[8px]">
      <div className="grid grid-cols-3 gap-[8px] rounded-md w-full py-[0px] px-[0px] sm:py-[8px] sm:px-[16px]">
        <div className="flex items-center col-span-3 sm:col-span-2 font-inriaSerif italic text-primary text-[20px]">
          <p className="truncate">{listingData.name}</p>
        </div>
        <div className="col-span-3 sm:col-span-1 gap-[16px] text-textSecondary">
          <div className="flex gap-[4px] items-center justify-end">
            <p className="text-[14px]">from</p>
            <p className="font-inriaSerif italic text-text text-[20px]">
              {price.toFormat("$0,0")}
            </p>
            <p>p/person</p>
          </div>
          <div className="justify-end gap-[16px] text-[14px]">
            <Select
              startDecorator={<CalendarSVG />}
              defaultValue="0"
              sx={{ backgroundColor: "#f7f6f3" }}
              onChange={onDateRangeChange}
            >
              <Option selected value="0">
                Select dates ({listingData?.availabilityData.length || 0}{" "}
                available)
              </Option>
              {listingData.availabilityData.map((availability) => (
                <Option value={availability._id}>
                  {DateTime.fromMillis(
                    availability.fromTimestamp
                  ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}{" "}
                  →{" "}
                  {DateTime.fromMillis(availability.toTimestamp).toLocaleString(
                    DateTime.DATE_MED_WITH_WEEKDAY
                  )}{" "}
                  ({availability?.inventory.length || 0} room options)
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
