import { DateTime } from "luxon";

export function calculateDaysToPackageCharge({ listing, availabilityItem }) {
  const travelDate = DateTime.fromMillis(availabilityItem.fromTimestamp);
  const travelDateWithPolicy = travelDate.minus({
    days: listing.chargeRemainingAmountDaysBeforeTravel,
  });

  const daysTillCharge = travelDateWithPolicy.diffNow("days").days;

  return {
    travelDateWithPolicy,
    daysTillCharge,
  };
}
