import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useContext } from "react";
import { ListingContext } from "../../../../../contexts/listing";
import Divider from "../../UI/Divider/Divider";
import SectionTitle from "../../UI/SectionTitle/SectionTitle";
import WarningMessage from "../../UI/WarningMessage/WarningMessage";

export default function MoreInformation({ content }) {
  return (
    <>
      <section className="w-full flex flex-col">
        <div className="flex flex-col lg:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
          <WeatherInformation content={content.weather} />
          <Insurance content={content.insurance} />
        </div>
        <Divider />
        <div className="flex flex-col lg:flex-row py-[16px] lg:py-[48px] gap-[24px] lg:gap-[32px]">
          <Vaccination content={content.vaccination} />
          <VisaInformation content={content.visa} />
        </div>
      </section>
      <Divider />
    </>
  );
}

const WeatherInformation = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.accommodationData[0].weatherAdvisory,
  });

  return (
    <section className="w-full flex flex-col gap-[24px]">
      <SectionTitle title="Weather Information" />
      <div
        className="space-y-4"
        dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
      ></div>
    </section>
  );
};

const Insurance = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.accommodationData[0].insuranceAdvisory,
  });

  return (
    <section className="w-full flex flex-col gap-[24px]">
      <SectionTitle title="Insurance" />
      <div
        className="space-y-4"
        dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
      ></div>
      <WarningMessage message="We strongly advise obtaining travel insurance to cover retreat activities, unexpected cancellations, illness, and travel disruptions. Ensure you also have health insurance for injury risks during the trip." />
    </section>
  );
};

const Vaccination = ({ content }) => {
  const { listingData } = useContext(ListingContext);
  const editor = useEditor({
    extensions: [StarterKit],
    content: listingData.accommodationData[0].vaccinationsAdvisory,
  });

  return (
    <section className="w-full  flex flex-col gap-[24px]">
      <SectionTitle title="Vaccination" />
      <div
        className="space-y-4"
        dangerouslySetInnerHTML={{ __html: editor.getHTML() }}
      ></div>
    </section>
  );
};

const VisaInformation = ({ content }) => {
  return (
    <section className="w-full flex flex-col gap-[24px]">
      <SectionTitle title="Visa Information" />
      <p>
        Always check the visa requirements when travelling to a retreat. Start
        by visiting the official website of the country you plan to visit where
        the retreat is being held. Look for their immigration or visa section.
        They often have online tools or checklists to help you determine if you
        need a visa based on your nationality and the purpose of your trip. If a
        visa is required, carefully review the application process, necessary
        documents (passport, photos, proof of funds, etc.), and application
        fees. Contact the nearest embassy or consulate of the country you're
        visiting for any specific questions or clarifications.
      </p>
      <WarningMessage
        message="It's always best to apply for a visa well in advance of
        your planned travel dates to allow for processing time."
      />
    </section>
  );
};
