import { CreditCard, InfoOutlined } from "@mui/icons-material";
import { Card, Link, Typography } from "@mui/joy";
import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { useNavigate } from "react-router-dom";
import FinalOptionsList from "../FinalOptionsList/FinalOptionsList";

export default function PaymentNotSuccessful({ orderData }) {
  // Fetch the failed scheuled payment
  const failedPayment = orderData.scheduledPayments.find(
    (payment) => payment.status === "failed"
  );

  const navigate = useNavigate();

  return (
    <Card color="danger">
      <Typography level="title-lg" color="danger">
        Sorry, your deposit payment was not successful
      </Typography>
      <Typography>
        We tried to process the deposit payment for this order but the payment
        was not successful.
      </Typography>
      <Typography>We tried to use the following payment method:</Typography>

      <Card color="danger">
        <Typography mb={-1}>
          Card type:{" "}
          {_.upperFirst(failedPayment?.lastPaymentMethodDetails?.card?.brand)}
        </Typography>
        <Typography mb={-1}>
          Card expiry:{" "}
          {failedPayment?.lastPaymentMethodDetails?.card?.exp_month} /{" "}
          {failedPayment?.lastPaymentMethodDetails?.card?.exp_year}
        </Typography>
        <Typography mb={-1}>
          Card ending: {failedPayment?.lastPaymentMethodDetails?.card?.last4}
        </Typography>
        <hr />
        <Typography>
          Declined because: {failedPayment?.lastOutcome?.seller_message}
        </Typography>
      </Card>

      {failedPayment.onOrAfterTimestamp && (
        <Typography startDecorator={<InfoOutlined />}>
          We're due to try this payment again{" "}
          {DateTime.fromMillis(failedPayment.onOrAfterTimestamp).toRelative()}.
        </Typography>
      )}

      <Link
        onClick={() => navigate("/my/payment-methods")}
        startDecorator={<CreditCard />}
      >
        Manage your payment methods
      </Link>
      <FinalOptionsList hideOrganiserContact order={orderData} />
    </Card>
  );
}
