import * as React from "react";

import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { init } from "../../services/firebase";
import { MeTimeAwayLogoBlack } from "../../themes/metimeaway/components/UI/SVG/MeTimeAwayLogoBlack";

init();

// FirebaseUI config.
var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      console.log("auth!", authResult);
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return false;
    },
  },
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: "https://app.getterms.io/view/iwska/tos/en-au",
  // Privacy policy url/callback.
  privacyPolicyUrl: "https://app.getterms.io/view/iwska/privacy/en-au",
  signInFlow: "popup",
};
export default function Login(props) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, setUser] = React.useState(false);

  auth.onAuthStateChanged(async (auth) => {
    if (auth) {
      setUser(auth);
      if (searchParams.get("returnTo")) {
        // @ts-ignore
        navigate(decodeURIComponent(searchParams.get("returnTo")));
      } else {
        navigate("/my/bookings");
      }
    }
  });

  React.useEffect(() => {
    var ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    setTimeout(() => {
      ui.start("#firebaseui-auth-container", uiConfig);
    });
  }, []);
  return (
    <>
      <div className="bg-neutral h-screen flex items-center justify-center">
        <div className="w-96">
          <div className="flex justify-center">
            <MeTimeAwayLogoBlack />
          </div>
          <p className="text-center my-5">Please sign in to continue</p>
          <div className="mt-9" id="firebaseui-auth-container"></div>
        </div>
      </div>
    </>
  );
}
